import React from 'react';
import { locationType } from 'shared/customPropTypes/reactRouterType';
import { Redirect } from 'react-router-dom';

const StripeRedirect = ({ location: { search } }) => {
  const params = new URLSearchParams(search);
  const showId = params.get('state');
  const code = params.get('code');

  return <Redirect to={`/shows/${showId}/save-stripe/${code}`} />;
};

StripeRedirect.propTypes = {
  location: locationType.isRequired,
};

export default StripeRedirect;

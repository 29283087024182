import React from 'react';
import styled from 'styled-components';
import {
  unit,
  Text,
  Switch,
  FormField,
  TextInput,
  TextArea,
  Box,
  toast,
  VStack,
  Button,
} from '@acast-tech/decibel';
import { TrashAltRegularGlyph } from '@acast-tech/decibel-glyphs';
import { Field, useField } from 'react-final-form';
import { useDropzone } from 'react-dropzone';
import Dropzone from '../Dropzone';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${unit(10)};
  padding: ${unit(16)} ${unit(32)};
  box-sizing: border-box;
`;

const StyledText = styled(Text)`
  margin: auto;
  text-align: center;
`;

const HeaderWrapper = styled.div`
  margin-bottom: ${unit(4)};
`;

const ImageWrapper = styled.div``;

const SettingsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: ${unit(15)};
  max-width: 544px;
  margin-top: ${unit(15)};
`;

const ImagePreviewWrapper = styled.div`
  display: flex;
  background-size: cover;
  flex-direction: column;
  width: 375px;
  height: 146px;
  margin: ${unit(2)} 0;
`;

const ButtonWrapper = styled.span`
  align-self: flex-end;
  margin-top: ${unit(2)};
`;

const DescriptionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: ${unit(4)};
`;

const StyledTextArea = styled(TextArea)`
  resize: none;
`;

const StyledFormField = styled(FormField)`
  div:last-of-type {
    display: none;
  }
`;

const validateDescription = (value) => {
  if (value && value.length > 400) {
    return 'Description is too long';
  }
};

const validateImage = (useImage, values) => {
  if (useImage && !values.imagePreview.url) {
    return 'Image is required';
  }
};

const Design = () => {
  const { input: imagePreview } = useField('imagePreview');
  const { input: useThankYouMessageInput } = useField('useThankYouMessage', { type: 'checkbox' });
  const { input: thankYouMessageInput } = useField('thankYouMessage');

  const onDrop = React.useCallback(
    (acceptedFiles) => {
      const [image] = acceptedFiles;
      if (!image) {
        toast.danger('This file is not supported', { closable: true, duration: 10000 });
        return;
      }
      const urlBlob = URL.createObjectURL(image);
      imagePreview.onChange({ url: urlBlob, name: image.path, file: image });
    },
    [imagePreview]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/jpeg, image/png' });
  return (
    <Container>
      <HeaderWrapper>
        <Text variant="h1">Design your landing page</Text>
        <Text variant="subtitle1">
          To the right you can see a preview of the Supporter landing page. Customize it below to
          suit your show.<i> You can always change the design later.</i>
        </Text>
      </HeaderWrapper>

      <Box my={15}>
        <Field name="displayCoverArt" type="checkbox">
          {({ input }) => <Switch label="Display show's cover art" {...input} />}
        </Field>
      </Box>

      <ImageWrapper>
        <Field validate={validateImage} name="useImage" type="checkbox">
          {({ input }) => (
            <>
              <Switch
                {...input}
                disabled={!!imagePreview.value.url}
                label="Use your own header image (recommended min. 1500x1000px)"
              />
              {input.checked && !imagePreview.value.url && (
                <Dropzone {...getRootProps()}>
                  <input {...getInputProps()} multiple={false} />
                  <StyledText variant="subtitle2">
                    Drag an image header
                    <br />
                    or click to upload
                  </StyledText>
                </Dropzone>
              )}
            </>
          )}
        </Field>

        {imagePreview.value.url && (
          <ImagePreviewWrapper>
            <div
              style={{
                backgroundImage: `url(${imagePreview.value.url})`,
                width: '375px',
                height: '146px',
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
                borderRadius: '4px',
              }}
            />
            <ButtonWrapper>
              <Button
                variant="tertiary"
                intent="danger"
                glyphStart={TrashAltRegularGlyph}
                onClick={() => {
                  imagePreview.onChange({});
                }}
              >
                Remove
              </Button>
            </ButtonWrapper>
          </ImagePreviewWrapper>
        )}
      </ImageWrapper>

      <SettingsWrapper>
        <StyledFormField label="Headline text">
          <Field name="title">{({ input }) => <TextInput {...input} />}</Field>
        </StyledFormField>
        <DescriptionWrapper>
          <StyledFormField label="Short description (max 400 characters)">
            <Field validate={validateDescription} name="description">
              {({ input }) => (
                <>
                  <StyledTextArea
                    {...input}
                    placeholder="This is where you place your call to action message"
                  />
                  {input.value && (
                    <Text variant="caption" mb={0} mt={1}>
                      Number of characters: {input.value.length}
                    </Text>
                  )}
                </>
              )}
            </Field>
          </StyledFormField>
        </DescriptionWrapper>

        <VStack gap={2}>
          <Switch
            {...useThankYouMessageInput}
            onChange={(e) => {
              useThankYouMessageInput.onChange(e.target.checked);
              thankYouMessageInput.onChange('');
            }}
            label="Add a thank you note to display after the payment goes through"
          />

          {useThankYouMessageInput.checked && (
            <StyledTextArea
              {...thankYouMessageInput}
              data-testid="thank-you-note-input"
              placeholder="(Optional) e.g. Thanks for your support!"
            />
          )}
        </VStack>
      </SettingsWrapper>
    </Container>
  );
};

export default Design;

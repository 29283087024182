import { useEffect } from 'react';

const NoMatch = () => {
  useEffect(() => {
    window.location = 'https://acast.com';
  }, []);
  return null;
};

export default NoMatch;

import React from 'react';
import { Route, useParams } from 'react-router-dom';
import { Box } from '@acast-tech/decibel';
import Success from './components/Success';
import DonateWrapper from './components/DonateWrapper';
import Datadog from './components/Datadog';

const Listener = () => {
  const { id } = useParams();

  return (
    <Box height="100%">
      <Datadog />
      <Route exact path="/:id" render={() => <DonateWrapper id={id} />} />
      <Route exact path="/:id/success" component={Success} />
      <Route exact path="/:id/failure" render={() => <DonateWrapper id={id} failure />} />
    </Box>
  );
};

export default Listener;

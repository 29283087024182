import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';

const Datadog = () => {
  useEffect(() => {
    datadogRum.init({
      applicationId: '1e876870-3e1f-485b-9d27-e02802107a4e',
      clientToken: 'pub38e0b603353ea59261de13470873a42f',
      datacenter: 'eu',
      sampleRate: 100,
    });
  }, []);

  return null;
};

export default Datadog;

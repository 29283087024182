import React from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import { unit } from '@acast-tech/decibel';

const CoverImageContainer = styled.div`
  ${({ displayCoverArt }) => !displayCoverArt && 'display: none;'}
  border: 1px solid white;
  border-radius: ${unit(2)};
  overflow: hidden;
  width: 100%;
  padding-top: 100%;
  background-image: ${({ src }) => `url(${src})`};
  background-size: cover;
  background-position: center center;
  z-index: 10;
`;

const CoverImage = ({ feederImage, device, displayCoverArt }) => {
  const isMobile = device === 'tablet' || device === 'mobile';

  return (
    <CoverImageContainer isMobile={isMobile} displayCoverArt={displayCoverArt} src={feederImage} />
  );
};

CoverImage.propTypes = {
  feederImage: T.string,
  device: T.string,
  displayCoverArt: T.bool,
};

CoverImage.defaultProps = {
  feederImage: '',
  device: 'mobile',
  displayCoverArt: true,
};

export default CoverImage;

import get from 'lodash/get';

let state = {};

export default {
  set(config) {
    state = Object.freeze(config);
  },

  get(key) {
    return get(state, key);
  },

  toJSON() {
    return state;
  },
};

import React, { useCallback, useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import api from 'shared/api';
import { Field, useField } from 'react-final-form';
import {
  Text,
  unit,
  FormField,
  TextInput,
  Box,
  colors,
  Select,
  SelectOption,
  toast,
  HStack,
} from '@acast-tech/decibel';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${unit(16)} ${unit(32)};

  label {
    color: ${colors.black64};
  }
`;

const StyledFormField = styled(FormField)`
  flex-grow: 0;
`;

const validateCurrency = (value) => {
  if (!value) {
    return 'Currency is required.';
  }
};

const Details = () => {
  const { input: suggestedAmountInput } = useField('suggestedAmount');
  const { input: currencyInput } = useField('currency', { validate: validateCurrency });

  const [CCY, setCCY] = useState({});

  const CCYlist = useMemo(() => {
    return CCY ? Object.keys(CCY) : [];
  }, [CCY]);

  const CCYmin = useMemo(() => {
    const val = CCY[currencyInput.value] || {};
    return val.min || 3;
  }, [currencyInput.value, CCY]);

  const CCYmax = useMemo(() => {
    const val = CCY[currencyInput.value] || {};
    return val.max || 100;
  }, [currencyInput.value, CCY]);

  const fetchCCY = useCallback(async () => {
    try {
      const currencies = await api.access.get(`api/v1/currencies`);
      setCCY(currencies);
    } catch {
      toast.danger('Error fetching currencies', {
        actions: [{ text: 'Retry', onClick: fetchCCY }],
        closable: true,
      });
    }
  }, []);

  useEffect(() => {
    fetchCCY();
  }, [fetchCCY]);

  const onBlurHandler = useCallback(
    (e) => {
      const {
        target: { value },
      } = e;
      const numOnly = Number(value);
      if (!numOnly) {
        suggestedAmountInput.onChange(`${CCYmin}`);
      }
    },
    [suggestedAmountInput, CCYmin]
  );

  const onChangeHandler = useCallback(
    (e) => {
      const {
        target: { value = '0' },
      } = e;
      if (value === '') {
        suggestedAmountInput.onChange(value);
        return;
      }

      const num = Number(value);
      if (!num || num > CCYmax) return;

      suggestedAmountInput.onChange(value);
    },
    [suggestedAmountInput, CCYmax]
  );

  return (
    <Container>
      <Box mb={12} flexDirection="column">
        <Text variant="h1">Support preferences</Text>
        <Text variant="subtitle1">Set the suggested support level.</Text>
      </Box>

      {CCY && (
        <Box my={12}>
          <StyledFormField
            label={`Suggested amount ${
              currencyInput.value
                ? `(between ${currencyInput.value} ${CCYmin}.00 - ${CCYmax}.00)`
                : ''
            }`}
            helperText={`12% fee will be deducted from the price.`}
          >
            <Field name="suggestedAmount" type="number">
              {({ input }) => (
                <>
                  <HStack gap={4}>
                    <Box>
                      <Select
                        {...currencyInput}
                        data-testid="currency-select"
                        onChange={({ target: { value } }) => {
                          suggestedAmountInput.onChange(CCY[value].min);
                          currencyInput.onChange(value);
                        }}
                      >
                        <SelectOption disabled value="">
                          Currency
                        </SelectOption>
                        {CCYlist.map((c) => (
                          <SelectOption key={c} value={c} />
                        ))}
                      </Select>
                    </Box>
                    <Box>
                      <TextInput
                        type="number"
                        disabled={!currencyInput.value}
                        min={`${CCYmin}`}
                        max={`${CCYmax}`}
                        {...input}
                        onBlur={onBlurHandler}
                        onChange={onChangeHandler}
                        data-testid="suggested-amount-input"
                        stretch
                      />
                    </Box>
                  </HStack>
                </>
              )}
            </Field>
          </StyledFormField>
        </Box>
      )}
    </Container>
  );
};

export default Details;

import React, { useState, useEffect, useCallback, createElement } from 'react';
import { Form } from 'react-final-form';
import styled from 'styled-components';
import { unit, toast } from '@acast-tech/decibel';
import LoaderWrapper from 'shared/components/LoaderWrapper';
import api from 'shared/api';
import { matchType, historyType } from 'shared/customPropTypes/reactRouterType';
import { createLabelFromSrc } from 'shared/helpers/utils/stringUtils';
import prerecordedAudios from '../../shared/common/prerecordedAudios';
import Progress from '../../shared/components/Progress';
import Details from '../../shared/components/Details';
import Design from '../../shared/components/Design';
import Audio from '../../shared/components/Audio';
import Summary from '../../shared/components/Summary';
import Preview from '../../shared/components/Preview';
import saveShow from '../../shared/common/saveShow';

const STEPS = { 1: Details, 2: Design, 3: Audio, 4: Summary };
const TOTAL_STEPS = Object.keys(STEPS).length;
const LAST_STEP = Object.keys(STEPS).length;

const InnerWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  min-height: ${`calc(100vh - ${unit(15)})`};
  padding-bottom: ${unit(15)};
`;

const Edit = ({
  match: {
    params: { id }, // Can be id or slug
  },
  history,
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [feederShow, setFeederShow] = useState();
  const [isFetching, setIsFetching] = useState(true);
  const [feederError, setFeederError] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const feederId = feederShow ? feederShow.showId || feederShow.id : null;

  useEffect(() => {
    (async () => {
      try {
        const data = await api.feeder.get(`api/v1/shows/${id}`);
        setFeederShow(data);
      } catch {
        setFeederError(true);
        toast.warning("Couldn't fetch show data. Reload page to try again.", {
          closable: true,
        });
      } finally {
        setIsFetching(false);
      }
    })();
  }, [id]);

  useEffect(() => {
    if (!feederId) {
      return;
    }

    const fetchAccessShow = async () => {
      setIsFetching(true);
      try {
        const {
          introAudio,
          image,
          displayCoverArt,
          suggestedAmount,
          description,
          thankYouMessage,
          title,
          currency,
          accepted,
        } = await api.access.get(`api/v1/shows/${feederId}`);
        const acastAudios = Object.keys(prerecordedAudios).map((key) => prerecordedAudios[key]);

        let audioType;

        if (introAudio) {
          audioType = acastAudios.includes(introAudio) ? 'acast' : 'own';
        }

        setInitialValues({
          suggestedAmount,
          title,
          audioPreview: {
            url: introAudio,
            name: createLabelFromSrc(introAudio),
          },
          displayCoverArt,
          thankYouMessage,
          useThankYouMessage: Boolean(thankYouMessage),
          useImage: Boolean(image),
          description,
          prerecordedAudio: introAudio || 'none',
          useAudio: Boolean(introAudio),
          audioType,
          accepted,
          currency,
          imagePreview: {
            url: image,
            name: createLabelFromSrc(image),
          },
        });
      } catch (e) {
        toast.danger(e.message, { closable: true, duration: 10000 });
      } finally {
        setIsFetching(false);
      }
    };
    fetchAccessShow();
  }, [feederId]);

  const onSubmit = useCallback(
    async (values) => {
      try {
        setIsFetching(true);
        await saveShow(values, feederId);
        toast.success('Show has been successfuly updated', { closable: true, duration: 10000 });
        history.push(`dashboard`);
      } catch (e) {
        setIsFetching(false);
        toast.danger(e.message, { closable: true, duration: 10000 });
      }
    },
    [history, feederId]
  );

  const onNext = useCallback(() => {
    if (currentStep === LAST_STEP) return;
    setCurrentStep(currentStep + 1);
  }, [currentStep]);

  const onBack = useCallback(() => {
    if (currentStep - 1 === 0) {
      return history.push('dashboard');
    }
    setCurrentStep(currentStep - 1);
  }, [currentStep, history]);

  const progress = (currentStep / TOTAL_STEPS) * 100;

  return (
    !feederError && (
      <LoaderWrapper loading={isFetching}>
        {initialValues && (
          <Form initialValues={initialValues} onSubmit={onSubmit}>
            {({ handleSubmit, values, errors, invalid }) => (
              <InnerWrapper>
                <form onSubmit={handleSubmit}>
                  {createElement(STEPS[currentStep], null, null)}
                  <Progress
                    progress={progress}
                    onNext={onNext}
                    onBack={onBack}
                    isLastStep={currentStep === LAST_STEP}
                    isFirstStep={currentStep === 1}
                    lastStepText="Update settings"
                    errors={errors}
                    invalid={invalid}
                  />
                </form>
                <Preview values={values} feederShow={feederShow} id={feederId} />
              </InnerWrapper>
            )}
          </Form>
        )}
      </LoaderWrapper>
    )
  );
};

Edit.propTypes = {
  match: matchType.isRequired,
  history: historyType.isRequired,
};

export default Edit;

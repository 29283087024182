import { useEffect, useState } from 'react';
import { tokenIsValid, logout } from 'shared/helpers/auth';
import { useLocation } from 'react-router-dom';

const useAuth = () => {
  const { pathname } = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(tokenIsValid());
  useEffect(() => {
    setIsAuthenticated(tokenIsValid());
  }, [pathname]);

  return { isAuthenticated, logout };
};

export default useAuth;

export default (name) => {
  const ext = name.substring(name.lastIndexOf('.')).toLowerCase();
  const validName = name
    .substring(0, name.lastIndexOf('.'))
    .toLowerCase()
    // eslint-disable-next-line no-useless-escape
    .replace(/[\s\(\)\[\]\}\{]+/gi, '_')
    .replace(/[,:.;/\\#?&]+/gi, '-')
    // eslint-disable-next-line no-useless-escape
    .replace(/[^a-zA-Z0-9-_\.]/g, '');

  return `${validName}${ext}`;
};

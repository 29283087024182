import React, { useEffect } from 'react';
import { GlobalStyle, Toaster } from '@acast-tech/decibel';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import NoMatch from './components/NoMatch';
import { version } from '../../package.json';
import Listener from './screens/Listener';
import Shows from './screens/Shows';
import Auth from './components/Auth';
import StripeRedirect from './components/StripeRedirect';
import MagicGenerator from './components/MagicGenerator';
import TermsAndConditions from './screens/TermsAndConditions';

const StyledToaster = styled(Toaster)`
  & > div {
    min-width: 300px;
    max-width: 95vw;
  }
`;

const isDev = process.env.NODE_ENV === 'development';

const App = () => {
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('- Version: ', version);
  }, []);

  return (
    <BrowserRouter>
      <GlobalStyle appRootSelector="#root" />
      <StyledToaster />
      <Switch>
        <Route path="/auth/:showId/:magicToken" component={Auth} />
        <Route path="/stripe-redirect" component={StripeRedirect} />
        <Route path="/terms-and-conditions" component={TermsAndConditions} />
        <Route path="/shows/:id" component={Shows} />
        <Route path="/:id" component={Listener} />
        <Route path="*" render={() => (isDev ? <MagicGenerator /> : <NoMatch />)} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;

import React, { useEffect, useReducer } from 'react';
import styled from 'styled-components';
import { Loader, Icon, colors } from '@acast-tech/decibel';
import { CheckLightGlyph } from '@acast-tech/decibel-glyphs';
import { matchType } from 'shared/customPropTypes/reactRouterType';
import api from 'shared/api';
import { useHistory } from 'react-router-dom';

const INITIAL_STATE = {
  isRegistering: false,
  success: false,
  error: null,
};

const ACTIONS = {
  REGISTER_REQUEST: 'REGISTER_REQUEST',
  REGISTER_ERROR: 'REGISTER_ERROR',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.REGISTER_REQUEST:
      return {
        ...state,
        isRegistering: true,
      };
    case ACTIONS.REGISTER_SUCCESS:
      return {
        ...state,
        isRegistering: false,
        success: true,
      };
    case ACTIONS.REGISTER_ERROR:
      return {
        ...state,
        isRegistering: false,
        success: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  place-items: center;
  text-align: center;
`;

const StyledLoader = styled(Loader)`
  margin: auto;
`;

const Info = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  place-items: center;
`;

const SaveStripe = ({
  match: {
    params: { id, stripeCode },
  },
}) => {
  const [{ isRegistering, success, error }, dispatch] = useReducer(reducer, INITIAL_STATE);
  const history = useHistory();

  useEffect(() => {
    const registerStripeCode = async () => {
      try {
        dispatch({ type: ACTIONS.REGISTER_REQUEST });
        await api.access.put(`api/v1/shows/${id}/registerstripecode`, {
          body: { stripeCode },
          auth: true,
        });
        dispatch({ type: ACTIONS.REGISTER_SUCCESS });
      } catch (e) {
        dispatch({ type: ACTIONS.REGISTER_ERROR, payload: e });
      }
    };
    registerStripeCode();
  }, [id, stripeCode]);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        history.replace(`/shows/${id}`);
      }, 5000);
    }
  }, [success, id, history]);

  return (
    <>
      <Wrapper>
        {isRegistering && <StyledLoader />}

        {success && (
          <Info>
            <Icon glyph={CheckLightGlyph} primaryColor={colors.green} size="xxlarge" />
            <span>
              Stripe connection successful. <br /> You will be redirected shortly.
            </span>
            <br />
            <StyledLoader />
          </Info>
        )}

        {error && <Info>{error.message}</Info>}
      </Wrapper>
    </>
  );
};

SaveStripe.propTypes = {
  match: matchType.isRequired,
};

export default SaveStripe;

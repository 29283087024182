import React, { useState, useEffect } from 'react';
import T from 'prop-types';
import { Box, Text, Loader, Icon } from '@acast-tech/decibel';
import { AcastFullGlyph } from '@acast-tech/decibel-glyphs';
import DonatePitch from 'shared/components/DonatePitch';
import useDevice from 'shared/hooks/useDevice';
import api from 'shared/api';

const DonateWrapper = ({ id, failure }) => {
  const device = useDevice();
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    (async () => {
      try {
        const feederShow = await api.feeder.get(`api/v1/shows/${id}`);
        const feederId = feederShow.showId || feederShow.id;
        const accessShow = await api.access.get(`api/v1/shows/${feederId}`);
        const dataToSet = { feederShow, accessShow };
        setData(dataToSet);
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [id]);

  return !data || error ? (
    <Box
      p={[10, 0]}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
    >
      {isLoading && <Loader />}
      {error && (
        <>
          <Icon width="100%" height="100px" size="xxlarge" glyph={AcastFullGlyph} />

          <Text textAlign="center">
            An error occurred. Make sure the show ID in the URL is correct and try again.
          </Text>

          <Text variant="caption">{error.status}</Text>
        </>
      )}
    </Box>
  ) : (
    <DonatePitch device={device} {...data} failure={failure} />
  );
};

DonateWrapper.propTypes = {
  id: T.string.isRequired,
  failure: T.bool,
};

DonateWrapper.defaultProps = {
  failure: false,
};

export default DonateWrapper;

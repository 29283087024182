import throttle from 'lodash/throttle';
import request from 'superagent';
import getValidFilename from '../../get-valid-filename';
import getSignedUrl from './get-signed-url';

const uploadAbortedError = () => new Error('Aborted');

const uploadFailedError = (statusCode) => new Error(`Upload failed with status code ${statusCode}`);

const getRemoteFilePath = (context) => {
  if (context.isAborted) {
    return Promise.reject(uploadAbortedError());
  }
  const { fileName } = context;
  const name = getValidFilename(`${context.name}-${Date.now().toString(36)}-${fileName}`);
  return `${context.path}/${name}`;
};

const sendFile = (context, options) => {
  const { data, cdnHost } = options;
  const setProgressThrottled = throttle((progress) => {
    if (!context.isComplete && !context.didFail && !context.isAborted) {
      context.setProgress(progress);
    }
  }, 300);

  return new Promise((resolve, reject) => {
    const { bucket, key } = data.fields;
    const form = new FormData();

    Object.keys(data.fields).forEach((fieldKey) => form.append(fieldKey, data.fields[fieldKey]));
    form.append('file', context.file);

    const req = request
      .post(`https://${bucket}.s3-accelerate.amazonaws.com`)
      .send(form)
      .on('progress', (evt) => {
        if (context.isAborted) {
          reject(uploadAbortedError());
          req.abort();
        } else if (!context.didFail) {
          setProgressThrottled(evt.percent / 100);
        }
      })
      .end((err, res) => {
        if (context.isAborted) {
          return;
        }
        if (err) {
          context.fail(err);
          reject(err);
        } else if (res.statusCode > 299) {
          const uploadError = uploadFailedError(res.statusCode);
          context.fail(uploadError);
          reject(uploadError);
        } else {
          context.done();
          resolve(`https://${cdnHost}/${key}`);
        }
      });
  });
};

const begin = async (context) => {
  const res = await getSignedUrl(getRemoteFilePath(context), context.entity);
  return sendFile(context, res);
};

export default {
  begin,
};

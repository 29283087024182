import React, { useState, useCallback } from 'react';
import { Box, Dialog, DialogBody, DialogCloseButton, Link } from '@acast-tech/decibel';
import T from 'prop-types';
import DonatePitch from 'shared/components/DonatePitch';
import styled from 'styled-components';

const PhonePreview = styled.div`
  width: 375px;
  height: 100%;
  max-height: 812px;
  border-radius: 20px;
  overflow: hidden scroll;
  position: relative;
`;

const StyledDialog = styled(Dialog)`
  max-width: unset;
  width: 95%;
  height: 90%;
  position: relative;
`;

const StyledDialogBody = styled(DialogBody)`
  padding: 0;
  cursor: not-allowed;
  position: relative;
  height: 100%;
`;

const StyledDialogCloseButton = styled(DialogCloseButton)`
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
`;

const Preview = ({ feederShow, values }) => {
  const [showDesktop, setshowDesktop] = useState(false);

  const togglePreview = useCallback(() => {
    setshowDesktop((pv) => !pv);
  }, [setshowDesktop]);

  return (
    <Box
      p={10}
      flexDirection="column"
      style={{ boxSizing: 'border-box', cursor: 'not-allowed' }}
      title="This is a preview of the landing page your listeners will see."
    >
      <Box mb={4} justifyContent="center">
        <Link onClick={togglePreview}>Click for a desktop preview</Link>
      </Box>
      <PhonePreview>
        {feederShow && (
          <DonatePitch
            device="mobile"
            isDemo
            feederShow={feederShow}
            accessShow={{
              showid: feederShow.showId || feederShow.id,
              ...values,
              image: values.imagePreview && values.imagePreview.url,
              suggestedAmount: Number(values.suggestedAmount) || 0,
            }}
          />
        )}
      </PhonePreview>
      <StyledDialog isOpen={showDesktop} onDismiss={togglePreview}>
        <StyledDialogBody>
          <StyledDialogCloseButton />
          {feederShow && showDesktop && (
            <DonatePitch
              device="desktop"
              isDemo
              feederShow={feederShow}
              accessShow={{
                showid: feederShow.showId || feederShow.id,
                ...values,
                image: values.imagePreview.url,
                suggestedAmount: Number(values.suggestedAmount),
              }}
            />
          )}
        </StyledDialogBody>
      </StyledDialog>
    </Box>
  );
};

Preview.propTypes = {
  values: T.object.isRequired,
  feederShow: T.shape({
    image: T.string,
    title: T.string.isRequired,
    id: T.string.isRequired,
    showId: T.string,
  }),
};

export default Preview;

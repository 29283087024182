import T from 'prop-types';

export default T.shape({
  showid: T.string.isRequired,
  introAudio: T.string,
  image: T.string,
  title: T.string,
  description: T.string,
  color: T.string,
  accepted: T.bool,
  enableOneTime: T.bool,
  suggestedAmount: T.number,
  displayCoverArt: T.bool,
  currencyLimits: T.object,
});

import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import useAuth from 'shared/hooks/useAuth';
import { matchType, historyType } from 'shared/customPropTypes/reactRouterType';
import api from 'shared/api';
import { toast, GlobalNavigation } from '@acast-tech/decibel';
import Signup from './screens/Signup';
import Setup from './screens/Setup';
import Edit from './screens/Edit';
import Finance from './screens/Finance';
import SaveStripe from './screens/SaveStripe';
import Dashboard from './screens/Dashboard';
import Datadog from './components/Datadog';
import Intercom from './components/Intercom';

const Shows = ({
  match: {
    params: { id },
    isExact,
  },
  history,
}) => {
  const { logout, isAuthenticated } = useAuth();

  useEffect(() => {
    if (!isAuthenticated) {
      logout();
      return;
    }
    const redirect = async () => {
      try {
        const res = await api.access.get(`api/v1/shows/${id}/info`, { auth: true });
        switch (res.status) {
          case 'noShow': {
            return history.replace(`/shows/${id}/signup`);
          }
          case 'noStripe': {
            return history.replace(`/shows/${id}/finance`);
          }
          case 'allSet': {
            return history.replace(`/shows/${id}/dashboard`);
          }
          default: {
            throw new Error(`Status not recognized: ${res.status}`);
          }
        }
      } catch (e) {
        toast.danger(`Something went wrong, ${e.message}`, { closable: true });
      }
    };
    if (isExact) {
      redirect();
    }
  }, [isAuthenticated, id, history, isExact, logout]);

  return (
    isAuthenticated && (
      <>
        <Datadog />
        <Intercom id={id} />
        <GlobalNavigation title="Supporter" />
        <Route exact path="/shows/:id/signup" component={Signup} />
        <Route exact path="/shows/:id/setup" component={Setup} />
        <Route exact path="/shows/:id/edit" component={Edit} />
        <Route exact path="/shows/:id/finance" component={Finance} />
        <Route exact path="/shows/:id/save-stripe/:stripeCode" component={SaveStripe} />
        <Route exact path="/shows/:id/dashboard" component={Dashboard} />
      </>
    )
  );
};

Shows.propTypes = {
  match: matchType.isRequired,
  history: historyType.isRequired,
};

export default Shows;

import config from 'shared/helpers/config';

const BUCKET_URL = config.get('BUCKET_URL');

const prerecordedAudios = {
  Australia: `${BUCKET_URL}/supporter_AU.mp3`,
  Denmark: `${BUCKET_URL}/supporter_DK.mp3`,
  France: `${BUCKET_URL}/supporter_FR.mp3`,
  Ireland: `${BUCKET_URL}/supporter_IE.mp3`,
  Norway: `${BUCKET_URL}/supporter_NO.mp3`,
  Sweden: `${BUCKET_URL}/supporter_SE.mp3`,
  'United Kingdom': `${BUCKET_URL}/supporter_UK.mp3`,
  'United States': `${BUCKET_URL}/supporter_US.mp3`,
};

export default prerecordedAudios;

import api from 'shared/api';
import fileUpload from 'shared/services/file-upload';

const saveShow = async (
  {
    suggestedAmount,
    displayCoverArt,
    title,
    description,
    imagePreview,
    audioPreview,
    currency,
    thankYouMessage,
    accepted,
  },
  feederId
) => {
  let introAudio = audioPreview.url;
  let image = imagePreview.url;
  if (imagePreview.file) {
    image = await fileUpload.begin(
      { path: `${feederId}/images`, id: feederId },
      name,
      imagePreview.file
    );
  }
  if (audioPreview.file) {
    introAudio = await fileUpload.begin(
      { path: `${feederId}/audio`, id: feederId },
      name,
      audioPreview.file
    );
  }

  const data = {
    suggestedAmount,
    displayCoverArt,
    title,
    description,
    introAudio,
    currency,
    image,
    thankYouMessage,
    accepted,
  };

  await api.access.put(`api/v1/shows/${feederId}`, { body: data, auth: true });
};

export default saveShow;

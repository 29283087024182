import React from 'react';
import { toast } from '@acast-tech/decibel';
import api from 'shared/api';

const Auth = ({
  match: {
    params: { magicToken, showId },
  },
  history,
}) => {
  React.useEffect(() => {
    const getToken = async () => {
      try {
        const res = await api.access.get(`api/v1/shows/${showId}/magiclink/${magicToken}`);
        if (res.token) {
          localStorage.setItem('token', res.token);
        } else {
          throw new Error('Token does not exist');
        }
        history.push(`/shows/${showId}`);
      } catch {
        toast.danger(
          'There was an authentication error, please contact our support at support@acast.com',
          { closable: true }
        );
      }
    };
    getToken();
  }, [magicToken, showId, history]);

  return null;
};

export default Auth;

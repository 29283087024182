import context from './context';
import UploadState from './state';
import worker from './providers/s3';

export default (upload, name, file) => {
  if (context.get(upload.id, name)) {
    throw new Error(`There appears to be a pending upload ${upload} ${name}`);
  }

  const fileName = file.name;
  const state = new UploadState({ entity: upload.id, path: upload.path, name, fileName });
  const ctx = context.create(() => {}, state, file);

  return worker.begin(ctx);
};

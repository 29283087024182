import React from 'react';
import styled from 'styled-components';
import T from 'prop-types';
import { colors, Button, unit } from '@acast-tech/decibel';

const Container = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background: ${colors.white};
  height: ${unit(15)};
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
`;

const Bar = styled.span`
  width: ${(props) => `${props.progress}%`};
  transition: width 0.5s;
  border-top: 2px solid ${colors.primary};
  align-self: flex-start;
  position: absolute;
`;

const ButtonsWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const Progress = ({
  onNext,
  onBack,
  progress,
  isLastStep,
  errors,
  invalid,
  isFirstStep,
  lastStepText,
}) => {
  // Getting the first encountered error
  const currentError = React.useMemo(() => {
    const keys = Object.keys(errors);

    if (keys.length > 0) {
      return errors[keys[0]];
    }
    return null;
  }, [errors]);

  const onNextClick = React.useCallback(() => {
    window.scrollTo(0, 0);
    onNext();
  }, [onNext]);

  const onBackClick = React.useCallback(() => {
    window.scrollTo(0, 0);
    onBack();
  }, [onBack]);

  return (
    <Container>
      <Bar progress={progress} />
      <ButtonsWrapper>
        <Button onClick={onBackClick} variant="secondary">
          {isFirstStep ? 'Cancel' : 'Back'}
        </Button>
        {isLastStep && (
          <Button type="submit" variant="primary">
            {lastStepText}
          </Button>
        )}
        {!isLastStep && (
          <Button
            disabled={invalid}
            title={currentError}
            type="button"
            variant="primary"
            onClick={onNextClick}
          >
            Next
          </Button>
        )}
      </ButtonsWrapper>
    </Container>
  );
};

Progress.propTypes = {
  onNext: T.func.isRequired,
  onBack: T.func.isRequired,
  progress: T.number.isRequired,
  isLastStep: T.bool.isRequired,
  errors: T.object,
  invalid: T.bool.isRequired,
  isFirstStep: T.bool.isRequired,
  lastStepText: T.string.isRequired,
};

export default Progress;

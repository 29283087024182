import React from 'react';
import styled, { css } from 'styled-components';
import T from 'prop-types';
import { Button, unit, Text, toast, colors, Link } from '@acast-tech/decibel';
import stripeHelper from 'shared/helpers/stripeHelper';

const StyledFooter = styled.div`
  grid-area: cta;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 105px;
  padding: ${unit(4)};
  background: white;
  box-shadow: 0px -1px 8px rgba(0, 0, 0, 0.05);
  align-self: flex-end;

  ${({ device }) =>
    device !== 'desktop' &&
    css`
      position: sticky;
      bottom: 0;
      left: 0;
      right: 0;
    `}
`;

const DonateCTA = React.forwardRef(({ stripe, children, isDemo, device }, ref) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const donateHandler = React.useCallback(async () => {
    if (isDemo) return;

    setIsLoading(true);
    try {
      await stripeHelper.goToCheckout(stripe);
    } catch (error) {
      if (error && error.message) {
        toast.danger(error.message, { closable: true, duration: 10000 });
      }
    } finally {
      setIsLoading(false);
    }
  }, [stripe, isDemo]);

  return (
    <StyledFooter device={device} isDemo={isDemo}>
      <Button variant="primary" intent="success" onClick={donateHandler} busy={isLoading} ref={ref}>
        {children}
      </Button>
      <Text mt={3} textColor={colors.black32} variant="caption">
        I agree to Acast{' '}
        <Link href="/terms-and-conditions" target="_blank" rel="nofollow noopener">
          Terms & Conditions
        </Link>{' '}
        and{' '}
        <Link href="https://www.acast.com/en/privacy" target="_blank" rel="noopener noreferrer">
          Privacy policy
        </Link>
        .
      </Text>
    </StyledFooter>
  );
});

DonateCTA.propTypes = {
  stripe: T.shape({
    showid: T.string.isRequired,
    amount: T.string.isRequired,
    title: T.string.isRequired,
    image: T.string,
    message: T.string,
    name: T.string,
  }).isRequired,
  children: T.string.isRequired,
  color: T.string,
  isDemo: T.bool,
  device: T.string.isRequired,
};

DonateCTA.defaultProps = {
  color: null,
  isDemo: false,
};

export default DonateCTA;

import React, { useRef, useState, useEffect, useCallback } from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import { IconButton, Box, TextInput, colors, HStack } from '@acast-tech/decibel';
import { MinusLightGlyph, PlusLightGlyph } from '@acast-tech/decibel-glyphs';
import { isNumbersOnly } from 'shared/helpers/utils/stringUtils';

const Currency = styled.div`
  font-size: 15px;
  color: ${colors.black32};
  height: 38px;
  display: flex;
  align-items: center;
  margin-right: 4px;
  padding: 0 15px;
`;

const AmountCents = styled.div`
  height: 38px;
  padding-top: 8px;
  font-weight: 600;
  font-size: 22px;
  padding-right: 15px;
`;

const StyledTextInput = styled(TextInput)`
  text-align: right;
  font-weight: 600;
  font-size: 22px;
  width: ${({ inputWidth }) => inputWidth || '2.5ch'};
  border: none;
  background: transparent;
  box-sizing: content-box;
  padding: 0;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

const AmountPicker = ({ suggestedAmount, currencyLimits, currency, onChange }) => {
  const [donateValue, setDonateValue] = useState(String(suggestedAmount));
  const inputRef = useRef();

  const { min = 1, max = 10000 } = currencyLimits || {};

  useEffect(() => {
    setDonateValue(String(suggestedAmount));
  }, [suggestedAmount]);

  useEffect(() => {
    onChange(donateValue);
  }, [donateValue, onChange]);

  const onChangeHandler = useCallback(
    (e) => {
      const value = e.target.value || '0';
      if (!isNumbersOnly(value)) return;
      const num = parseInt(value, 10);
      if (num > max) return;
      setDonateValue(`${num}`);
    },
    [setDonateValue, max]
  );

  const onBlurHandler = useCallback(() => {
    if (donateValue === '' || new Number(donateValue) < min) {
      setDonateValue(`${suggestedAmount}`);
    }
  }, [setDonateValue, suggestedAmount, donateValue, min]);

  return (
    <HStack gap={4}>
      <IconButton
        data-testid="amount-picker-decrease"
        disabled={donateValue <= min}
        onClick={() =>
          setDonateValue((pv) => {
            return `${new Number(pv) - 1}`;
          })
        }
        variant="tertiary"
        size="large"
        shape="square"
        glyph={MinusLightGlyph}
        aria-label="Decrease amount"
      />
      <Box onClick={() => inputRef.current.focus()} onKeyDown={() => inputRef.current.focus()}>
        <StyledTextInput
          ref={inputRef}
          data-testid="amount-picker-input"
          value={donateValue}
          type="number"
          inputWidth={() => {
            const length = donateValue.length || 1;
            return `${length}.1ch`;
          }}
          onBlur={onBlurHandler}
          onChange={onChangeHandler}
          addonStart={<Currency>{currency || ''}</Currency>}
          addonEnd={<AmountCents>.00</AmountCents>}
        />
      </Box>
      <IconButton
        data-testid="amount-picker-increase"
        disabled={donateValue >= max}
        onClick={() =>
          setDonateValue((pv) => {
            return `${new Number(pv) + 1}`;
          })
        }
        variant="tertiary"
        size="large"
        shape="square"
        glyph={PlusLightGlyph}
        aria-label="Increase amount"
      />
    </HStack>
  );
};

AmountPicker.propTypes = {
  currencyLimits: T.shape({
    min: T.number.isRequired,
    max: T.number.isRequired,
  }),
  suggestedAmount: T.number.isRequired,
  onChange: T.func.isRequired,
  currency: T.string.isRequired,
};

export default AmountPicker;

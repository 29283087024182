import React, { useState, useEffect } from 'react';
import { Box, Icon, Text, toast, Loader, Image, unit, colors } from '@acast-tech/decibel';
import styled from 'styled-components';
import { AcastFullGlyph } from '@acast-tech/decibel-glyphs';
import { useParams } from 'react-router-dom';
import { chainedCalls } from 'shared/api';
import config from 'shared/helpers/config';
import useQuery from 'shared/hooks/useQuery';

const NavBar = styled(Box)`
  border-bottom: 1px solid rgba(13, 13, 13, 0.08);
  height: 62px;
  justify-content: space-between;
  align-items: center;
`;

const ImageContainer = styled(Box)`
  border-radius: 8px;
  border: 1px solid rgba(13, 13, 13, 0.16);
  width: 165px;
  height: 165px;
  margin: ${unit(5)} auto;
  overflow: hidden;
`;

const TextContainer = styled(Box)`
  display: flex;
  justify-content: center;
  padding: ${unit(5)};
  background: ${colors.purple8};
  box-sizing: border-box;
  border-radius: 4px;
`;

const QuoteIcon = styled.div`
  padding-right: ${unit(2)};
  fill: ${colors.primary};
`;

const Success = () => {
  const { id } = useParams();
  const query = useQuery();
  const [data, setData] = useState();

  const ccy = query.get('ccy');
  const amnt = query.get('amnt');

  const { feederShow, accessShow } = data || {};
  const { image } = feederShow || {};
  const { thankYouMessage, id: accessId } = accessShow || {};

  useEffect(() => {
    (async () => {
      try {
        const response = await chainedCalls.accessAndFeeder(id);
        const assigned = Object.assign({}, ...response);
        setData(assigned);
      } catch (error) {
        toast.danger('Error fetching data', { closable: true });
      }
    })();
  }, [id]);

  return data ? (
    <Box flexDirection="column" width="100%" alignItems="center">
      <script>
        {(function (d, s, fbid) {
          var js,
            fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(fbid)) return;
          js = d.createElement(s);
          js.id = fbid;
          js.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.0';
          fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk')}
      </script>
      <NavBar width="100%" px={4}>
        <Icon glyph={AcastFullGlyph} size="xlarge" />
        <div
          className="fb-share-button"
          data-href={`${config.get('BASE_URL')}/${accessId}`}
          data-layout="button_count"
        />
      </NavBar>
      <Box flexDirection="column" width="100%" p={[10, 10]} alignItems="center">
        <Text variant="h4" textAlign="center">
          We&apos;ve received your support!
        </Text>
        <ImageContainer>
          <Image src={image} alt="podcast cover" height="165" />
        </ImageContainer>
        {ccy && amnt && (
          <Text variant="h1">
            {ccy} {amnt}
          </Text>
        )}
        <TextContainer width={['100%', '80%', '30%', '25%']} margin="0 auto">
          <QuoteIcon>
            <svg width="22" height="15" viewBox="0 0 22 15" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.87397 14.7609C3.49397 14.7609 2.48197 14.4543 1.83797 13.8409C1.22464 13.1969 0.917969 12.3996 0.917969 11.4489V10.4829C0.917969 9.5936 1.04064 8.70427 1.28597 7.81494C1.56197 6.89494 1.91464 6.02094 2.34397 5.19294C2.80397 4.36494 3.34064 3.59827 3.95397 2.89294C4.5673 2.15694 5.22664 1.51294 5.93197 0.960938H10.21C9.19797 1.88094 8.32397 2.81627 7.58797 3.76694C6.88264 4.7176 6.34597 5.7756 5.97797 6.94094C6.9593 7.12494 7.67997 7.53894 8.13997 8.18294C8.59997 8.79627 8.82997 9.5016 8.82997 10.2989V11.4489C8.82997 12.3996 8.50797 13.1969 7.86397 13.8409C7.25064 14.4543 6.25397 14.7609 4.87397 14.7609ZM15.684 14.7609C14.304 14.7609 13.292 14.4543 12.648 13.8409C12.0346 13.1969 11.728 12.3996 11.728 11.4489V10.4829C11.728 9.5936 11.8506 8.70427 12.096 7.81494C12.372 6.89494 12.7246 6.02094 13.154 5.19294C13.614 4.36494 14.1506 3.59827 14.764 2.89294C15.3773 2.15694 16.0366 1.51294 16.742 0.960938H21.02C20.008 1.88094 19.134 2.81627 18.398 3.76694C17.6926 4.7176 17.156 5.7756 16.788 6.94094C17.7693 7.12494 18.49 7.53894 18.95 8.18294C19.41 8.79627 19.64 9.5016 19.64 10.2989V11.4489C19.64 12.3996 19.318 13.1969 18.674 13.8409C18.0606 14.4543 17.064 14.7609 15.684 14.7609Z" />
            </svg>
          </QuoteIcon>
          <Text textAlign="center" m="0">
            {thankYouMessage}
          </Text>
        </TextContainer>
      </Box>
      <Text variant="caption">© Acast, 2020</Text>
    </Box>
  ) : (
    <Box width="100%" height="100vh" justifyContent="center" alignItems="center">
      {!data && <Loader />}
    </Box>
  );
};

export default Success;

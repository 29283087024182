import styled from 'styled-components';
import { unit, colors, Button } from '@acast-tech/decibel';

const Dropzone = styled(Button)`
  margin: ${unit(4)} 0;
  width: 544px;
  height: 146px;
  border: 1px dashed ${colors.purple32};
  display: flex;
  place-items: center;
  &:hover {
    background-color: transparent;
    border: 1px dashed ${colors.purple72};
  }
  &:active {
    background-color: transparent;
  }
  &:focus {
    border: 1px solid ${colors.purple56};
  }
`;

export default Dropzone;

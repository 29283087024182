import T from 'prop-types';

export const locationType = T.shape({
  hash: T.string.isRequired,
  key: T.string,
  pathname: T.string.isRequired,
  search: T.string.isRequired,
  state: T.oneOfType([T.array, T.bool, T.number, T.object, T.string]),
});

export const matchType = T.shape({
  isExact: T.bool,
  params: T.object.isRequired,
  path: T.string.isRequired,
  url: T.string.isRequired,
});

export const historyType = T.shape({
  action: T.oneOf(['PUSH', 'REPLACE', 'POP']).isRequired,
  block: T.func.isRequired,
  canGo: T.func,
  createHref: T.func.isRequired,
  entries: T.arrayOf(locationType),
  go: T.func.isRequired,
  goBack: T.func.isRequired,
  goForward: T.func.isRequired,
  index: T.number,
  length: T.number,
  listen: T.func.isRequired,
  location: locationType.isRequired,
  push: T.func.isRequired,
  replace: T.func.isRequired,
});

import React from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { createLabelFromSrc } from 'shared/helpers/utils/stringUtils';
import {
  Text,
  unit,
  Switch,
  toast,
  Radio,
  FormField,
  Select,
  SelectOption,
} from '@acast-tech/decibel';
import { Field, useField } from 'react-final-form';
import AudioPreview from 'shared/components/AudioPreview';
import prerecordedAudioList from '../../common/prerecordedAudios';
import Dropzone from '../Dropzone';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${unit(16)} ${unit(32)};
`;

const StyledText = styled(Text)`
  margin: auto;
  text-align: center;
`;

const HeaderWrapper = styled.div`
  margin-bottom: ${unit(15)};
`;

const StyledSubtext = styled(Text)`
  margin-top: ${unit(2)};
`;

const SelectWrapper = styled.div`
  width: 50%;
`;

const RadioWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-row-gap: ${unit(4)};
  margin: ${unit(8)} 0;
`;

const validateAudio = (useAudio, values) => {
  if (useAudio && !values.audioPreview.url) {
    return 'Audio file is required';
  }
};

const Audio = () => {
  const { input: audioPreview } = useField('audioPreview');
  const { input: useAudio } = useField('useAudio');
  const { input: audioType } = useField('audioType');
  const { input: prerecordedAudio } = useField('prerecordedAudio');
  const onDrop = React.useCallback(
    (acceptedFiles) => {
      const [audio] = acceptedFiles;
      if (!audio) {
        toast.danger('This file is not supported', { closable: true, duration: 10000 });
        return;
      }
      const urlBlob = URL.createObjectURL(audio);
      audioPreview.onChange({ url: urlBlob, name: audio.path, file: audio });
    },
    [audioPreview]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ['audio/mp3', 'audio/mpeg'],
  });

  return (
    <Container>
      <HeaderWrapper>
        <Text variant="h1">Promo audio</Text>
        <Text variant="subtitle1">
          Add your own introductory promo message (up to 30 seconds) inviting your listeners to
          support your show. Or use one of our pre-recorded promos. The promo will play as a first
          pre-roll on all the episodes in your show. It will not replace any existing sponsorships
          or ads.
        </Text>
      </HeaderWrapper>
      <Field validate={validateAudio} name="useAudio" type="checkbox">
        {({ input }) => (
          <>
            <Switch
              label="Use a promotional audio file"
              {...input}
              onChange={(e) => {
                audioPreview.onChange({});
                prerecordedAudio.onChange('none');
                input.onChange(e);
              }}
            />
            <StyledSubtext variant="subtitle2">
              Upload your own or use one of our pre-recorded promo audio files
            </StyledSubtext>
          </>
        )}
      </Field>
      {useAudio.value && (
        <>
          <RadioWrapper>
            <Field name="audioType" type="radio" value="own">
              {({ input }) => (
                <Radio
                  {...input}
                  onChange={(e) => {
                    audioPreview.onChange({});
                    prerecordedAudio.onChange('none');
                    input.onChange(e);
                  }}
                  label="Upload your own promo file"
                />
              )}
            </Field>
            <Field name="audioType" type="radio" value="acast">
              {({ input }) => (
                <Radio
                  {...input}
                  onChange={(e) => {
                    audioPreview.onChange({});
                    prerecordedAudio.onChange('none');
                    input.onChange(e);
                  }}
                  label="Use an Acast recorded promo"
                />
              )}
            </Field>
          </RadioWrapper>
          {audioType.value === 'own' && !audioPreview.value.url && (
            <Dropzone {...getRootProps()}>
              <input {...getInputProps()} multiple={false} />
              <StyledText variant="subtitle2">
                Drag a max 30 second audio file here
                <br />
                or click to upload
              </StyledText>
            </Dropzone>
          )}
          {audioType.value === 'acast' && (
            <Field name="prerecordedAudio">
              {({ input }) => (
                <SelectWrapper>
                  <FormField label="Choose prerecorded audio">
                    <Select
                      {...input}
                      onChange={({ target: { value } }) => {
                        audioPreview.onChange({ url: value, name: createLabelFromSrc(value) });
                        input.onChange(value);
                      }}
                    >
                      <SelectOption disabled value="none">
                        --- Select audio ---
                      </SelectOption>
                      {Object.keys(prerecordedAudioList).map((key) => (
                        <SelectOption key={key} value={prerecordedAudioList[key]}>
                          {key}
                        </SelectOption>
                      ))}
                    </Select>
                  </FormField>
                </SelectWrapper>
              )}
            </Field>
          )}
          {audioPreview.value.url && (
            <AudioPreview name={audioPreview.value.name} url={audioPreview.value.url} />
          )}
        </>
      )}
    </Container>
  );
};

export default Audio;

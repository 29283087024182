import React from 'react';
import styled from 'styled-components';
import { FormSpy } from 'react-final-form';
import { Text, unit, Section, SectionSeparator } from '@acast-tech/decibel';
import AudioPreview from 'shared/components/AudioPreview';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${unit(16)} ${unit(32)};
`;

const Summary = () => {
  return (
    <Container>
      <Text variant="h1">Summary</Text>
      <Text variant="subtitle1">Review your choices below</Text>
      <FormSpy>
        {({
          values: { title, description, suggestedAmount, thankYouMessage, audioPreview, currency },
        }) => {
          return (
            <Section>
              <Text variant="h4">Title</Text>
              <Text variant="body1">{title}</Text>
              <SectionSeparator />
              <Text variant="h4">Description</Text>
              <Text variant="body1">{description || 'None'}</Text>
              <SectionSeparator />
              <Text variant="h4">Suggested amount</Text>
              <Text variant="body1">
                {currency} {suggestedAmount}
              </Text>
              <SectionSeparator />
              <Text variant="h4">Promo file</Text>
              {audioPreview.url ? (
                <AudioPreview name={audioPreview.name} url={audioPreview.url} />
              ) : (
                <Text variant="body1">None</Text>
              )}
              <SectionSeparator />
              <Text variant="h4">&quot;Thank you&quot; note</Text>
              <Text variant="body1">{thankYouMessage || 'None'}</Text>
            </Section>
          );
        }}
      </FormSpy>
    </Container>
  );
};

export default Summary;

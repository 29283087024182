import { useState, useEffect, useCallback } from 'react';

const DEVICES = {
  mobile: 'mobile',
  tablet: 'tablet',
  desktop: 'desktop',
};

export const BREAKPOINTS = {
  mobile: 0,
  tablet: 415,
  desktop: 1100,
};

function getWidth() {
  return Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  );
}

const determineDevice = (width) => {
  if (width >= BREAKPOINTS.desktop) {
    return DEVICES.desktop;
  }
  if (width >= BREAKPOINTS.tablet) {
    return DEVICES.tablet;
  }
  return DEVICES.mobile;
};

const useDevice = () => {
  const [device, setDevice] = useState(null);

  const calculateWidth = useCallback(() => {
    const currentWidth = getWidth();
    const currentDevice = determineDevice(currentWidth);
    setDevice(currentDevice);
  }, [setDevice]);

  useEffect(() => {
    calculateWidth();
  }, [calculateWidth]);

  useEffect(() => {
    window.addEventListener('resize', calculateWidth);
    return () => {
      window.removeEventListener('resize', calculateWidth);
    };
  }, [calculateWidth]);

  return device;
};

export default useDevice;

import React, { useState, useEffect, useCallback, createElement } from 'react';
import { Form } from 'react-final-form';
import styled from 'styled-components';
import { unit, toast } from '@acast-tech/decibel';
import LoaderWrapper from 'shared/components/LoaderWrapper';
import api from 'shared/api';
import { matchType, historyType } from 'shared/customPropTypes/reactRouterType';
import saveShow from '../../shared/common/saveShow';
import Progress from '../../shared/components/Progress';
import Details from '../../shared/components/Details';
import Design from '../../shared/components/Design';
import Audio from '../../shared/components/Audio';
import Summary from '../../shared/components/Summary';
import Preview from '../../shared/components/Preview';

const STEPS = { 1: Details, 2: Design, 3: Audio, 4: Summary };
const TOTAL_STEPS = Object.keys(STEPS).length;
const LAST_STEP = Object.keys(STEPS).length;

const InnerWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  min-height: ${`calc(100vh - ${unit(15)})`};
  padding-bottom: ${unit(15)};
`;

const initialValues = {
  suggestedAmount: 5,
  title: 'Help support our show',
  displayCoverArt: true,
  audioPreview: {},
  imagePreview: {},
  useAudio: true,
  audioType: 'own',
  prerecordedAudio: 'none',
  currency: '',
};

const Setup = ({
  match: {
    params: { id }, // Can be id or slug
  },
  history,
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [feederShow, setFeederShow] = useState();
  const [isFetching, setIsFetching] = useState(true);
  const [feederError, setFeederError] = useState(false);
  const feederId = feederShow ? feederShow.showId || feederShow.id : null;
  const {
    location: { state = {} },
  } = history;

  useEffect(() => {
    (async () => {
      try {
        const data = await api.feeder.get(`api/v1/shows/${id}`);
        setFeederShow(data);
      } catch {
        setFeederError(true);
        toast.warning("Couldn't fetch show data. Reload page to try again.", {
          closable: true,
        });
      } finally {
        setIsFetching(false);
      }
    })();
  }, [id]);

  const onSubmit = useCallback(
    async (values) => {
      try {
        setIsFetching(true);
        await saveShow({ ...values, accepted: state.accepted }, feederId);
        toast.success(
          'Show has been successfuly setup, fill in your finance details as the last step.',
          { closable: true }
        );
        history.push(`finance`);
      } catch (e) {
        toast.danger(e.message, { closable: true, duration: 10000 });
        setIsFetching(false);
      }
    },
    [feederId, history, state]
  );

  const onNext = useCallback(() => {
    if (currentStep === LAST_STEP) return;
    setCurrentStep(currentStep + 1);
  }, [currentStep]);

  const onBack = useCallback(() => {
    if (currentStep - 1 === 0) {
      return history.push('signup');
    }
    setCurrentStep(currentStep - 1);
  }, [currentStep, history]);

  const progress = (currentStep / TOTAL_STEPS) * 100;

  return (
    !feederError && (
      <LoaderWrapper loading={isFetching}>
        <Form initialValues={initialValues} onSubmit={onSubmit}>
          {({ handleSubmit, values, errors, invalid }) => (
            <InnerWrapper>
              <form onSubmit={handleSubmit}>
                {createElement(STEPS[currentStep], null, null)}
                <Progress
                  progress={progress}
                  onNext={onNext}
                  onBack={onBack}
                  isLastStep={currentStep === LAST_STEP}
                  isFirstStep={currentStep === 1}
                  lastStepText="Set up financial information"
                  errors={errors}
                  invalid={invalid}
                />
              </form>
              <Preview values={values} feederShow={feederShow} id={feederId} />
            </InnerWrapper>
          )}
        </Form>
      </LoaderWrapper>
    )
  );
};

Setup.propTypes = {
  match: matchType.isRequired,
  history: historyType.isRequired,
};

export default Setup;

import React from 'react';
import styled from 'styled-components';
import T from 'prop-types';
import { unit, Text } from '@acast-tech/decibel';

const AudioWrapper = styled.div`
  margin: ${unit(4)} 0;
`;

const AudioTextWrapper = styled.div`
  display: flex;
`;

const AudioPreview = ({ url, name }) => {
  return (
    <AudioWrapper key={url}>
      <AudioTextWrapper>
        <Text variant="subtitle2">{name}</Text>
      </AudioTextWrapper>
      <audio controls>
        <track kind="captions" />
        <source src={url} type="audio/mp3" />
      </audio>
    </AudioWrapper>
  );
};

AudioPreview.propTypes = {
  url: T.string.isRequired,
  name: T.string.isRequired,
};

export default AudioPreview;

import Actions from './actions';

const activeContexts = new Map();

function create(dispatch, state, file) {
  dispatch({
    type: Actions.CREATE,
    state,
  });

  const ctx = {
    sas: null,
    blockSize: 256 * 1024,
    numBlocks: 0,
    bytesSent: 0,
    blocks: [],
    isComplete: false,
    isAborted: false,
    didFail: false,

    get file() {
      return file;
    },

    get fileSize() {
      return file.size;
    },

    get fileName() {
      return file.name;
    },

    get entity() {
      return state.entity;
    },

    get name() {
      return state.name;
    },

    get path() {
      return state.path;
    },

    get progress() {
      // TODO: This will return a stale value,
      //       we need to query for latest state here.
      return state.progress;
    },

    setProgress(value) {
      dispatch({
        type: Actions.UPDATE,
        state: state.merge({
          progress: value,
        }),
      });
    },

    fail(error) {
      this.didFail = true;
      this.isComplete = false;
      dispatch({
        type: Actions.UPDATE,
        state: state.merge({
          error,
        }),
      });
    },

    done() {
      this.didFail = false;
      this.isComplete = true;
      dispatch({
        type: Actions.DELETE,
        entity: state.entity,
        name: state.name,
      });
      activeContexts.delete(`${state.entity}/${state.name}`);
    },

    abort() {
      this.isAborted = true;
      dispatch({
        type: Actions.UPDATE,
        state: state.merge({
          error: new Error('Aborted'),
          progress: 0,
        }),
      });
      this.done();
    },
  };

  activeContexts.set(`${state.entity}/${state.name}`, ctx);

  return ctx;
}

function get(entity, name) {
  return activeContexts.get(`${entity}/${name}`) || null;
}

export default {
  create,
  get,
};

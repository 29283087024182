import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';

const Datadog = () => {
  useEffect(() => {
    datadogRum.init({
      applicationId: 'ad9fd443-a8bb-40f2-b4d9-32e18324d613',
      clientToken: 'pub4d5d85cb62f27f5f9c6e6402b4974de7',
      datacenter: 'eu',
      sampleRate: 100,
    });
  }, []);

  return null;
};

export default Datadog;

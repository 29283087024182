import { loadStripe } from '@stripe/stripe-js';
import config from 'shared/helpers/config';
import api from 'shared/api';

const isLocal = process.env.REACT_APP_IS_LOCAL;

const getPaymentIntent = async (id, body) =>
  api.access.put(`api/v1/shows/${id}/payments`, { body });

const redirectToCheckout = async (sessionId) => {
  const STRIPE_CHECKOUT_KEY = config.get('STRIPE_CHECKOUT_KEY');
  const stripe = await loadStripe(STRIPE_CHECKOUT_KEY);
  const { error } = await stripe.redirectToCheckout({
    sessionId,
  });

  if (error) throw error;
};

/**
 * Fetches payment intent from backend and initiates the Stripe checkout SDK.
 *
 * @param {Object} data Object containing the data required to create the payment intent
 * @param {string} data.showid showID of show receiving donation/payment
 * @param {number} data.amount amount to be payed/donated
 * @param {string} data.title name of podscast to display in stripe checkout
 * @param {string} data.image image of cover for podcast
 * @param {string} [data.message] Optional message to the podcaster
 * @param {string} [data.name] Optional nickname of the supporter
 */
const goToCheckout = async ({ showid, amount, title, image, message, name }) => {
  let BASE_URL = config.get('BASE_URL');

  if (isLocal) {
    BASE_URL = 'http://localhost:3000';
  }

  const successUrl = `${BASE_URL}/${showid}/success`;
  const cancelUrl = `${BASE_URL}/${showid}/failure`;

  try {
    const payload = {
      amount,
      title,
      image,
      cancelUrl,
      successUrl,
      name,
    };

    if (message) payload.message = message;

    const paymentIntent = await getPaymentIntent(showid, payload);

    const { id: paymentId } = paymentIntent;

    await redirectToCheckout(paymentId);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('STRIPE HELPER', error);
    throw error;
  }
};

export default {
  goToCheckout,
};

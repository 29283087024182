import React from 'react';
import ReactIntercom from 'react-intercom';
import T from 'prop-types';

const Intercom = ({ id }) => (
  <ReactIntercom
    appID="ayi0335i"
    {...{ show: id, feedmanager: `https://feed-manager.acast.com/shows/${id}` }}
  />
);

Intercom.propTypes = {
  id: T.string.isRequired,
};

export default Intercom;

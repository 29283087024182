import React, { useCallback, useState, useMemo } from 'react';
import { Field, useField } from 'react-final-form';
import styled from 'styled-components';
import T from 'prop-types';
import api from 'shared/api';
import LoaderWrapper from 'shared/components/LoaderWrapper';
import {
  unit,
  FormField,
  Select,
  SelectOption,
  Button,
  TextInput,
  Radio,
  colors,
  Icon,
  toast,
  Text,
  HStack,
  Box,
} from '@acast-tech/decibel';
import { CheckSolidGlyph } from '@acast-tech/decibel-glyphs';
import StripeButton from './components/StripeButton';

const StyledFrom = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: ${unit(14)};
  margin-bottom: ${unit(8)};
`;

const RadioWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  margin-bottom: ${unit(8)};
`;

const GreenBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${unit(8)};
  background: #eefbf5;
  padding: ${unit(6)};
  border: 2px solid ${colors.black16};
  border-radius: ${unit(1)};
`;

const Form = ({ onSubmit, countries, checkIfEu }) => {
  const { input: country } = useField('country');
  const { input: type } = useField('type');
  const { input: vat } = useField('vatNumber');
  const isCompany = type.value === 'company';
  const [companyData, setCompanyData] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const validateVat = useCallback(async () => {
    setIsProcessing(true);
    toast.closeAll();
    try {
      const res = await api.access.get(`api/v1/getvatinfo?vatNumber=${country.value}${vat.value}`, {
        auth: true,
      });
      if (!res.valid) {
        toast.danger(`VAT number is invalid.`, {
          duration: 10000,
          closable: true,
        });
      }
      setCompanyData(res);
    } catch (e) {
      toast.danger(`There was a problem validating VAT number. ${e.message}`, {
        duration: 10000,
        closable: true,
      });
    } finally {
      setIsProcessing(false);
    }
  }, [vat, country]);

  const isFormValid = useMemo(() => {
    if (country.value === 'none') {
      return false;
    }

    if (!isCompany) {
      return true;
    }

    const isEU = checkIfEu(country.value);

    if (isCompany && !isEU) {
      return true;
    }

    if (isCompany && isEU && companyData && companyData.valid) {
      return true;
    }

    return false;
  }, [companyData, country, isCompany, checkIfEu]);

  return (
    <LoaderWrapper loading={isProcessing}>
      <StyledFrom onSubmit={onSubmit}>
        <Text variant="h3">Step 1. Enter your financial information</Text>
        <Field name="country">
          {({ input }) => (
            <FormField label="Country:">
              <Select
                {...input}
                data-testid="country-select"
                onChange={(val) => {
                  vat.onChange(undefined);
                  setCompanyData(null);
                  input.onChange(val);
                }}
              >
                <SelectOption disabled value="none">
                  --- Select your country ---
                </SelectOption>
                {countries.map(({ code, name }) => (
                  <SelectOption key={code} value={code}>
                    {name}
                  </SelectOption>
                ))}
              </Select>
            </FormField>
          )}
        </Field>
        <RadioWrapper>
          <Field name="type" type="radio" value="individual">
            {({ input }) => (
              <Radio
                {...input}
                onChange={(e) => {
                  setCompanyData(null);
                  input.onChange(e);
                }}
                label="Private individual"
              />
            )}
          </Field>

          <Field name="type" type="radio" value="company">
            {({ input }) => (
              <Radio
                {...input}
                onChange={(e) => {
                  setCompanyData(null);
                  input.onChange(e);
                }}
                label="Business"
              />
            )}
          </Field>
        </RadioWrapper>
        {checkIfEu(country.value) && isCompany && (
          <FormField label="VAT number">
            <HStack gap={2}>
              <Box size={14}>
                <TextInput value={country.value} disabled />
              </Box>
              <Field name="vatNumber">
                {({ input }) => (
                  <TextInput
                    {...input}
                    data-testid="vat-number"
                    onChange={(val) => {
                      setCompanyData(null);
                      input.onChange(val);
                    }}
                  />
                )}
              </Field>
              <Button disabled={!vat.value} onClick={validateVat} type="button">
                Validate
              </Button>
            </HStack>
          </FormField>
        )}
        {companyData && companyData.valid && isCompany && (
          <GreenBox>
            <span>
              {companyData.name}, {companyData.address}
            </span>
            <Icon glyph={CheckSolidGlyph} primaryColor={colors.green} />
          </GreenBox>
        )}
        <Text variant="h3">Step 2. Connect to Stripe</Text>
        <Text variant="subtitle1">
          Stripe is a secure, global payment provider we use to process payments. Each transaction
          will be subject to a 12% total fee.
        </Text>
        <StripeButton disabled={!isFormValid} type="submit">
          <span>Connect with Stripe</span>
        </StripeButton>
      </StyledFrom>
    </LoaderWrapper>
  );
};

Form.propTypes = {
  onSubmit: T.func.isRequired,
  countries: T.array.isRequired,
  checkIfEu: T.func.isRequired,
};

export default Form;

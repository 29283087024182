import React from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import { Loader } from '@acast-tech/decibel';

const Wrapper = styled.div`
  position: relative;
  opacity: ${({ processing }) => (processing ? 0.5 : 1)};
  min-height: ${({ processing }) => (processing ? '100vh' : 'auto')};
  min-width: ${({ processing }) => (processing ? '100vw' : 'auto')};
`;

const Obscure = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
`;

const StyledLoader = styled(Loader)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  z-index: 101;
`;

const LoaderWrapper = ({ children, loading }) => {
  return (
    <Wrapper processing={loading}>
      {loading && <Obscure />}
      {loading && <StyledLoader />}
      {children}
    </Wrapper>
  );
};

LoaderWrapper.propTypes = {
  loading: T.bool,
  children: T.node,
};

LoaderWrapper.defaultProps = {
  loading: false,
};

export default LoaderWrapper;

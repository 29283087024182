import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { Text, colors, unit, Button, Checkbox, Link, Box, HStack } from '@acast-tech/decibel';
import iPhone from './iPhone.png';
import GiantAcastA from './GiantAcastA.svg';

const Wrapper = styled(HStack)`
  background-image: url(${GiantAcastA});
  background-size: 50% 100%;
  background-repeat: no-repeat;
  background-position: bottom right;
  background-color: ${colors.purple4};
`;

const RightColumn = styled(Box)`
  background: transparent;
  height: 100vh;
`;

const InfoWrapper = styled.div`
  margin-bottom: ${unit(8)};
`;

const TopPart = styled.div`
  margin-bottom: ${unit(8)};
`;

const ConfirmWrapper = styled.div`
  margin-bottom: ${unit(8)};
`;

const CheckboxWrapper = styled.div`
  margin: ${unit(4)} 0;
  background: ${colors.purple8};
  padding: ${unit(8)};
  border: 1px solid ${colors.purple16};
  box-sizing: border-box;
  border-radius: 4px;
`;

const PhoneWrapper = styled.div`
  z-index: 3;
`;

const StyledButton = styled(Button)`
  margin-bottom: ${unit(4)};
`;

const Signup = () => {
  const [hasAccepted, setHasAccepted] = useState(false);
  const { id } = useParams();
  const history = useHistory();

  return (
    <Wrapper>
      <Box
        bg={colors.purple4}
        p={unit(24)}
        flexDirection="column"
        justifyContent="center"
        width="100%"
      >
        <TopPart>
          <Text variant="h1">Get support right when you need it</Text>
          <Text variant="subtitle1">
            As part of our commitment to developing great new tools for our creators, we’ve launched
            our Supporter feature. By signing up, you can easily invite listeners to provide one-off
            financial contributions.
          </Text>
        </TopPart>
        <InfoWrapper>
          <Text variant="h3">Receive monetary support</Text>
          <Text variant="subtitle1">
            Let listeners show their support for your podcast through one-off payments. There’s no
            regular subscription or commitment, and your fans can give as much or as little as they
            like.
          </Text>
        </InfoWrapper>
        <InfoWrapper>
          <Text variant="h3">Record your own promo</Text>
          <Text variant="subtitle1">
            Add a message for listeners to new and existing episodes in your own style — or use one
            of our pre-recorded promos.
          </Text>
        </InfoWrapper>
        <InfoWrapper>
          <Text variant="h3">Available everywhere</Text>
          <Text variant="subtitle1">
            Get support from listeners everywhere, regardless of the podcatcher they use. Just add
            your Supporter link to your show description and you’re good to go.
          </Text>
        </InfoWrapper>
        <ConfirmWrapper>
          <CheckboxWrapper>
            <Checkbox
              value={hasAccepted}
              onChange={() => setHasAccepted((pv) => !pv)}
              label={
                <div>
                  I consent to Acast Supporter{' '}
                  <Link href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">
                    Terms and Conditions
                  </Link>{' '}
                  and{' '}
                  <Link
                    href="https://www.acast.com/en/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy policy
                  </Link>
                  .
                </div>
              }
            />
          </CheckboxWrapper>
          <StyledButton
            onClick={() => history.push(`/shows/${id}/setup`, { accepted: hasAccepted })}
            variant="primary"
            intent="success"
            disabled={!hasAccepted}
          >
            I want to sign up
          </StyledButton>
        </ConfirmWrapper>
        <Text variant="caption">2020 ACAST © All Rights Reserved.</Text>
      </Box>
      <RightColumn
        bg={colors.purple4}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width={['0%', '0%', '100%', '100%']}
      >
        <PhoneWrapper>
          <img
            src={iPhone}
            style={{ maxWidth: '700px', width: '100%', height: 'auto' }}
            alt="Phone"
          />
        </PhoneWrapper>
      </RightColumn>
    </Wrapper>
  );
};

export default Signup;

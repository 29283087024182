import React, { useEffect, useState, useRef } from 'react';
import T from 'prop-types';
import {
  Box,
  Text,
  unit,
  colors,
  toast,
  Icon,
  TextInput,
  FormField,
  TextArea,
} from '@acast-tech/decibel';
import { Helmet } from 'react-helmet';
import config from 'shared/helpers/config';
import { AcastFullGlyph } from '@acast-tech/decibel-glyphs';
import styled, { css } from 'styled-components';
import { BREAKPOINTS } from 'shared/hooks/useDevice';
import accessShowType from 'shared/customPropTypes/accessShowType';
import DonateCTA from './components/DonateCTA';
import CoverImage from './components/CoverImage';
import AmountPicker from './components/AmountPicker';

const TabletCSSMixin = css`
  .cover {
    justify-content: space-between;
    h3 {
      font-size: 34px;
    }
  }

  .content {
    justify-content: flex-start;
    width: 100%;
    padding-top: ${unit(10)};
    padding-bottom: ${unit(10)};
    grid-template-columns: 1fr;
    overflow-y: inherit;

    .title {
      font-size: 26px;
    }
  }

  grid-template-columns: 1fr;
  grid-template-rows: 300px 1fr 110px;
  grid-template-areas:
    'cover'
    'content'
    'cta';

  max-width: ${BREAKPOINTS.desktop}px;
  margin: 0 auto;
`;

const MobileCSSMixin = css`
  grid-template-rows: 190px 1fr 110px;
  max-width: ${BREAKPOINTS.tablet}px;
  margin: 0 auto;

  .cover {
    h3 {
      font-size: 28px;
    }
  }

  .content {
    padding: ${unit(10)};
    padding-bottom: ${unit(10)};

    .description {
      font-size: 16px;
    }

    .title {
      font-size: 20px;
    }
  }
`;

const Wrapper = styled(Box)`
  display: grid;
  margin: 0 auto;
  width: 100%;
  height: ${({ isDemo }) => (isDemo ? '100%' : '100vh')};

  position: relative;
  background: ${colors.white};

  grid-template-areas:
    'cover content'
    'cover cta';
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 110px;

  .cover {
    grid-area: cover;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #252525;
    background-size: cover;
    background-position: center center;
    padding: ${unit(4)};

    h3 {
      z-index: 1;
      font-size: 40px;
    }

    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: ${colors.black}99;
    }
  }

  .content {
    grid-area: content;
    display: grid;
    grid-template-columns: 50%;
    grid-gap: ${unit(5)};
    justify-items: center;
    align-items: center;
    height: 100%;
    position: relative;
    padding: ${unit(10)} ${unit(4)};
    background: ${colors.white};
    overflow-y: scroll;
    justify-content: center;

    .description {
      font-size: 16px;
      line-height: 20px;
    }

    .title {
      font-size: 24px;
      line-height: 28px;
    }
  }

  ${({ device }) => device !== 'desktop' && TabletCSSMixin}
  ${({ device }) => device === 'mobile' && MobileCSSMixin}
`;

const StyledTextArea = styled(TextArea)`
  min-height: 100px;
  resize: none;
  padding: ${unit(2)};
`;

const IconBox = styled(Box)`
  position: absolute;
  left: 0;
  top: 0;
  padding-left: ${unit(6)};
`;

const env = config.get('BASE_URL');

const DonatePitch = ({ accessShow, feederShow, device, failure, isDemo }) => {
  const {
    showid,
    image,
    title,
    description,
    suggestedAmount = 2,
    displayCoverArt,
    currencyLimits,
    currency,
  } = accessShow;

  const { image: feederImage, title: showName, showUrl } = feederShow;

  const [donateValue, setDonateValue] = useState(String(suggestedAmount));
  const [message, setMessage] = useState('');
  const [name, setName] = useState();
  const ctaRef = useRef();

  useEffect(() => {
    if (failure)
      toast.danger(
        "Donation failed for some reason, please try again or contact support if it's not working.",
        { closable: true, duration: 100000 }
      );
  }, [failure]);

  return (
    <>
      {!isDemo && (
        <Helmet>
          <meta property="og:url" content={`${env}/${showUrl}`} />
          <meta property="og:type" content="support" />
          <meta property="og:title" content={`Give support to ${showName}`} />
          <meta
            property="og:description"
            content="Help the creators out to give them a better opportunity to deliver more content for everyone to enjoy! Supporter by Acast"
          />
          <meta property="og:image" content={feederImage} />
          <link rel="canonical" href={`${env}/${showid}`} />
          {failure && <meta name="robots" content="noindex" />}
        </Helmet>
      )}
      <Wrapper device={device} isDemo={isDemo}>
        <div
          className="cover"
          style={{ backgroundImage: `url(${image || '/imgs/headphones.jpeg'})` }}
        >
          <div className="overlay" />
          <IconBox>
            <Icon
              secondaryColor="#FBFBFB"
              glyph={AcastFullGlyph}
              size="70px"
              style={{ zIndex: 10 }}
            />
          </IconBox>
          {device !== 'desktop' && (
            <Box alignSelf="flex-end" width="18%" alignItems="flex-start">
              <CoverImage
                displayCoverArt={displayCoverArt}
                feederImage={feederImage}
                device={device}
              />
            </Box>
          )}
          <Text variant="h3" textColor="white" textAlign="center" data-testid="show-name">
            {showName}
          </Text>
        </div>
        <div className="content">
          {device === 'desktop' && (
            <CoverImage
              displayCoverArt={displayCoverArt}
              feederImage={feederImage}
              device={device}
            />
          )}

          <Box flexDirection="column" alignItems="center">
            <Text className="title" my="10px" variant="h5" textAlign="center">
              {title}
            </Text>
            <Text
              className="description"
              my="10px"
              variant="body2"
              textAlign="center"
              textColor={colors.black64}
              style={{ whiteSpace: 'pre-line', overflowWrap: 'anywhere' }}
            >
              {description}
            </Text>
          </Box>

          <Box flexDirection="column" alignItems="center">
            <AmountPicker
              {...{ currencyLimits, suggestedAmount, currency }}
              onChange={setDonateValue}
            />
            <Text mt={1} variant="caption">
              One time (taxes included)
            </Text>
          </Box>
          <Box pt={10} flexDirection="column" width="100%">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (!isDemo) {
                  ctaRef.current.click();
                }
              }}
            >
              <FormField label="Nickname (optional)">
                <TextInput
                  value={name || ''}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Choose a nickname"
                />
              </FormField>
              <FormField label="Send us a message (optional)">
                <StyledTextArea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Thanks for a great show, have a cup of coffee on me!"
                />
              </FormField>
            </form>
          </Box>
        </div>
        <DonateCTA
          ref={ctaRef}
          stripe={{
            showid,
            amount: String(donateValue),
            title,
            image: feederImage,
            message,
            name,
          }}
          isDemo={isDemo}
          device={device}
        >
          Support this show
        </DonateCTA>
      </Wrapper>
    </>
  );
};

DonatePitch.propTypes = {
  accessShow: accessShowType,
  feederShow: T.shape({
    image: T.string,
    title: T.string.isRequired,
    showUrl: T.string.isRequired,
  }),
  device: T.string,
  failure: T.bool,
  isDemo: T.bool,
};

DonatePitch.defaultProps = {
  feederShow: {},
  accessShow: { displayCoverArt: true },
  device: 'mobile',
  failure: false,
  isDemo: false,
};

export default DonatePitch;

import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  DataTable,
  Text,
  Button,
  toast,
  DateInput,
  Dialog,
  DialogBody,
  DialogHeader,
  DialogFooter,
  VStack,
  HStack,
  Section,
  unit,
} from '@acast-tech/decibel';
import {
  PencilRegularGlyph,
  ExternalLinkLightGlyph,
  LinkLightGlyph,
  CalendarAltLightGlyph,
} from '@acast-tech/decibel-glyphs';
import { useParams, useHistory } from 'react-router-dom';
import api from 'shared/api';
import LoaderWrapper from 'shared/components/LoaderWrapper';
import usePrevious from 'shared/hooks/usePrevious';
import styled from 'styled-components';

const SectionHeader = styled(Box)`
  margin-bottom: ${unit(6)};
`;

const Dashboard = () => {
  const { id } = useParams();
  const history = useHistory();
  const [showData, setShowData] = useState({});
  const [charges, setCharges] = useState([]);
  const [stripeLink, setStripeLink] = useState();
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(false);
  const [showDateInput, setShowDateInput] = useState(false);
  const [dates, setDates] = useState([]);
  const [stripeError, setStripeError] = useState(false);
  const [isActive, setIsActive] = useState(null);
  const [invoices, setInvoices] = useState([]);

  const { showUrl, title = '', showId, id: oldId } = showData || {};
  const feederId = showId || oldId;
  const prevFID = usePrevious(feederId);
  const prevIsActive = usePrevious(isActive);

  const getCharges = useCallback(async (fid, range) => {
    try {
      const paginationQuery = () => {
        if (!range) return '';
        const [from, to] = range;
        if (!from || !to) return '';

        const after = from.getTime() / 1000;
        const before = to.getTime() / 1000;
        return `?created_after=${after}&created_before=${before}`;
      };

      setFetching(true);
      const chargesData = await api.access.get(`api/v1/shows/${fid}/charges${paginationQuery()}`, {
        auth: true,
      });
      setCharges(chargesData.charges);
    } catch (e) {
      setError(true);
      toast.danger(`Something went wrong: ${e.message}`, { duration: 10000, closable: true });
    } finally {
      setFetching(false);
    }
  }, []);

  useEffect(() => {
    if (feederId && feederId === prevFID) {
      getCharges(feederId, dates);
    }
  }, [getCharges, dates, feederId, prevFID]);

  useEffect(() => {
    if (feederId && feederId === prevFID && showDateInput === false && dates.length > 1) {
      getCharges(feederId);
    }
  }, [getCharges, showDateInput, feederId, dates, prevFID]);

  useEffect(() => {
    (async () => {
      try {
        setFetching(true);
        const data = await api.feeder.get(`api/v1/shows/${id}`);
        setShowData(data);

        const fID = data.showId || data.id;
        await getCharges(fID);

        const stripeaccount = await api.access.get(`api/v1/shows/${fID}/stripeaccount`, {
          auth: true,
        });
        const {
          url,
          payouts_enabled: payoutsEnabled,
          charges_enabled: chargesEnabled,
        } = stripeaccount;

        setStripeLink(url);

        if (payoutsEnabled !== true || chargesEnabled !== true) {
          setStripeError(true);
          toast.danger(
            'Payments paused: You need to go to the Stripe dashboard to provide more information',
            { closable: true }
          );
        }
      } catch (e) {
        setError(true);
        toast.danger(`Something went wrong: ${e.message}`, { duration: 10000, closable: true });
      } finally {
        setFetching(false);
      }
    })();
  }, [id, getCharges]);

  useEffect(() => {
    (async () => {
      if (feederId) {
        try {
          await api.access.get(`api/v1/shows/${feederId}`, { auth: true });
          setIsActive(true);
        } catch (err) {
          setIsActive(false);
        }

        try {
          const i = await api.access.get(`api/v1/shows/${feederId}/invoices`, {
            auth: true,
          });
          setInvoices(i);
        } catch (e) {
          toast.danger("Couldn't fetch invoices", { closable: true });
        }
      }
    })();
  }, [feederId]);

  const toggleActiveHandler = useCallback(async () => {
    try {
      setFetching(true);
      await api.access.put(`api/v1/shows/${feederId}/status?active=${!isActive}`, {
        auth: true,
      });
      setIsActive((pv) => !pv);
      toast.warning(`Supporter feature turned ${prevIsActive === true ? 'off' : 'on'}`, {
        closable: true,
      });
    } catch {
      toast.danger('Failed to disable or enable Supporter feature, try again.', { closable: true });
    } finally {
      setFetching(false);
    }
  }, [feederId, isActive, prevIsActive]);

  return error ? null : (
    <LoaderWrapper loading={fetching}>
      <Dialog isOpen={stripeError === true} onDismiss={() => setStripeError(false)}>
        <DialogHeader>
          <Text variant="h5" mb={0}>
            Cannot process payments
          </Text>
        </DialogHeader>
        <DialogBody>
          <Text variant="body1">
            Stripe needs more information from you to process payments. Please go to the stripe
            dashboard to solve this.
          </Text>
        </DialogBody>
        <DialogFooter>
          <Button onClick={() => setStripeError(false)}>Close</Button>
          <Button
            as="a"
            href={stripeLink}
            target="_blank"
            rel="nofollow noreferrer noopener"
            variant="primary"
            intent="danger"
            glyphEnd={ExternalLinkLightGlyph}
          >
            Go to dashboard
          </Button>
        </DialogFooter>
      </Dialog>
      <Box py={6} px={15} display="block">
        <VStack gap={5}>
          <Box justifyContent="space-between" alignItems="center" flexWrap="wrap">
            <Text variant="h1" mb={0}>
              {title}
            </Text>
            <HStack gap={2} wrap>
              {typeof isActive === 'boolean' && (
                <Button
                  onClick={toggleActiveHandler}
                  intent={isActive ? 'danger' : 'default'}
                  variant="primary"
                >
                  {isActive ? 'Disable Supporter' : 'Enable Supporter'}
                </Button>
              )}

              <Button onClick={() => history.push('edit')} glyphEnd={PencilRegularGlyph}>
                Edit settings
              </Button>
              <Button
                as="a"
                href={`/${showUrl}`}
                target="_blank"
                rel="nofollow noopener"
                glyphEnd={LinkLightGlyph}
              >
                Visit supporter page
              </Button>
            </HStack>
          </Box>

          <Box width={['100%', '80%', '50%', '33%']}>
            <Section>
              <Text variant="h3" mb={0}>
                {charges.length}
              </Text>
              <Text variant="subtitle1" mb={0}>
                One-time supporters
              </Text>
            </Section>
          </Box>

          <Section>
            <SectionHeader justifyContent="space-between" alignItems="center" flexWrap="wrap">
              <Text variant="h3" mb={0}>
                Supporters
              </Text>
              <HStack gap={2} wrap>
                {showDateInput && (
                  <Box>
                    <Button
                      onClick={() => {
                        setShowDateInput(false);
                      }}
                    >
                      No filter
                    </Button>
                    <DateInput
                      range
                      onChange={(val) => {
                        if (val[0] && val[1]) {
                          setDates(val);
                        }
                      }}
                      placeholder={['Start date', 'End date']}
                    />
                  </Box>
                )}
                {!showDateInput && (
                  <Button onClick={() => setShowDateInput(true)} glyphEnd={CalendarAltLightGlyph}>
                    Filter by date
                  </Button>
                )}

                <Button
                  disabled={!stripeLink || error}
                  as="a"
                  href={stripeLink}
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  glyphEnd={ExternalLinkLightGlyph}
                >
                  Go to Stripe dashboard
                </Button>
              </HStack>
            </SectionHeader>

            <DataTable
              title="Supporters table"
              columns={[
                { id: 'name', title: 'Nickname' },
                { id: 'date', title: 'Date', align: 'left' },
                { id: 'amount', title: 'Amount', align: 'left' },
                {
                  id: 'message',
                  title: 'Message',
                  align: 'right',
                  render: (row) => (
                    <Text m={0} style={{ whiteSpace: 'pre-wrap' }}>
                      {row.message}
                    </Text>
                  ),
                },
              ]}
              data={charges.map((c) => {
                const { metadata, currency, amount, created } = c || {};
                const { message, name } = metadata || {};
                return {
                  id: created,
                  message: message,
                  amount: `${currency.toUpperCase()} ${amount}`,
                  name: name || 'Anonymous',
                  date: new Date(created * 1000).toLocaleDateString(),
                };
              })}
            />
          </Section>

          <Section>
            <SectionHeader>
              <Text variant="h3" mb={0}>
                Invoices
              </Text>
            </SectionHeader>

            <DataTable
              title="Invoices table"
              columns={[
                { id: 'fileName', title: 'Invoice' },
                {
                  id: 'download',
                  title: 'Download',
                  align: 'right',
                  render: (row) => (
                    <Button as="a" href={row.url} target="blank" rel="nofollow nopener noreferrer">
                      Download
                    </Button>
                  ),
                },
              ]}
              data={
                Array.isArray(invoices)
                  ? invoices.map((c) => {
                      const { fileName, url } = c || {};
                      return {
                        id: fileName,
                        fileName,
                        url,
                      };
                    })
                  : []
              }
            />
          </Section>
        </VStack>
      </Box>
    </LoaderWrapper>
  );
};

export default Dashboard;

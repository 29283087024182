/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import styled from 'styled-components';
import { unit } from '@acast-tech/decibel';

const Wrapper = styled.main`
  margin: ${unit(8)};
  max-width: 600px;
`;

// Styles for this text is automatically generated with https://wordtohtml.net/
// T&C source is https://docs.google.com/document/d/1Mg5l7L2qHFJCefTfqdV13pW6S8N2w25i89MQIHT-izE/edit

const TermsAndConditions = () => {
  return (
    <Wrapper>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;Terms of Service – Acast Supporter Service
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          The Platform and the Acast Supporter Service, as defined below, are provided to you by
          Acast AB (publ), a company registered in Sweden ("We" or "Acast").
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          These terms of service (the "Terms") constitute a legally binding agreement between you
          and Acast and contains the terms and conditions upon which Acast permits you to access and
          use the Acast Supporter Service.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          By clicking the checkbox stating that you accept these Terms or by using the Acast
          Supporter Service, you agree to be bound by and comply with the Terms as well as the&nbsp;
        </span>
        <a href="https://www.acast.com/en/privacy" style={{ textDecoration: 'none' }}>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Arial',
              color: '#1155cc',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Privacy Policies
          </span>
        </a>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;and&nbsp;
        </span>
        <a href="https://www.acast.com/en/cookies" style={{ textDecoration: 'none' }}>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Arial',
              color: '#1155cc',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Cookie Policy
          </span>
        </a>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;(and any amendments thereto that Acast may publish from time to time). If you accept
          these Terms on behalf of a company or other legal entity, you represent and warrant that
          you have the authority to bind such entity to these Terms. If you do not have such
          authority, or if you do not agree with these Terms, you may not use the Acast Supporter
          Service.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          If you are a podcaster you accept, by clicking the checkbox stating that you accept these
          Terms or by using the Acast Supporter Service, that you also agree to be bound by and
          comply with the&nbsp;
        </span>
        <a href="https://www.acast.com/en/tos" style={{ textDecoration: 'none' }}>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Arial',
              color: '#1155cc',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Acast Open Terms of Service
          </span>
        </a>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;upon written notice from Acast and that the Acast Open Terms of Service will replace
          any and all previous agreements regarding hosting, distribution and/or monetisation of
          your Publications.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          1. DEFINITIONS
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          In these Terms, unless otherwise expressly stated, the following terms shall have the
          meaning set out below, to be equally applicable to the singular and plural forms of such
          terms:
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          "Acast Supporter Service" means an Acast platform service which enables podcasters to get
          support through payments by their listeners.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          “Effective Date” means the date and time (your local time) on which you accept the Terms
          by clicking a checkbox stating that you accept these Terms or by using the Acast Supporter
          Service.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          "Intellectual Property Rights" means unregistered and registered intellectual property
          rights of whatsoever nature throughout the world, such as, but not limited to copyrights,
          designs, domain names, trade names, trademarks, whether registered or earned, and patents,
          whether pending or approved, and applications and rights to apply for the same.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          "Platform" means the entire technical platform used by and provided by Acast under these
          Terms, including, but not limited to all software related to the Platform Services.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          “Platform Services” means any service that Acast in its sole discretion and from time to
          time decides to make available on the Platform.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          “Supporter” means a listener or anyone who uses the Acast Supporter Service for the
          purpose of supporting an Acast podcaster.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          “Termination Date” means the date and time (your local time) on which your subscription to
          the Platform Service ends.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          "Terms" means these Terms of Service, applicable to your use of the Platform and the Acast
          Supporter Service and all associated information and content therein.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          2. THE ACAST SUPPORTER SERVICE
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          2.1 Acast shall make available the Acast Supporter Service as set out in these Terms.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          2.2 Acast shall use reasonable efforts to make the Acast Supporter Service available 24
          hours a day, and 365 days a year (“Availability”). You acknowledge and agrees that the
          Acast Supporter Service may be unavailable from time to time due to (i) equipment,
          software or service malfunctions; (ii) maintenance and update procedures or repairs; or
          (iii) causes beyond the control of Acast, including, without limitation, interruption or
          failure of telecommunication or digital transmission links, malicious attacks, the
          unavailability, operation, or inaccessibility of websites or interfaces, network
          congestion or other failures, and that Acast shall not be liable for any unavailability
          caused by any of the foregoing. In the event that the Acast Supporter Service does not
          meet the Availability for any reason other than as set forth in the foregoing (i) through
          (iii) for at least two (2) consecutive months, your sole remedy and Acast’s exclusive
          liability for unavailability of the Acast Supporter Service, is to terminate the Terms and
          the account upon written notice to Acast. In addition, you acknowledge and agree that
          Acast has no responsibility or liability with respect to the operation of any third party
          payment solution, such as e.g. Stripe, as may be used to collect and transfer any
          payments.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          2.3 In order to use the Acast Supporter Service, you must obtain access to the internet.
          Acast is not responsible for any service fees associated with such access, nor is Acast
          responsible for any costs or equipment necessary for you to make such connection to the
          internet or be able to use the Acast Supporter Service.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          3. ACCOUNT
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'italic',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          For podcasters
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          3.1 As a creator you need to have an account with Acast for hosting your audio content.
          Your account is personal to you and may not be transferred to or shared with others. You
          warrant that any and all user information provided by you to Acast in connection with
          creating an account, setting up your use of the Acast Supporter Service or otherwise
          provided in connection with your use of the Acast Supporter Service is true and correct
          and that, when necessary, you will update your user information so that it remains true
          and correct at all times.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          3.2 You also need to create an account with Stripe (or any other third party payment
          solution provider that Acast uses from time to time) in accordance with Stripe’s terms and
          conditions and accept such terms and conditions (you will need to undertake to comply with
          “know your customer” requirements, provide proof of ID, tax ID, physical address, phone
          number, residency in a Stripe approved country etc). You agree that you will comply with
          any and all obligations imposed by such terms and conditions and that you will use any and
          all tools made available by Stripe to limit and avoid fraud, money laundering and/or
          breach of any applicable trade restrictions and/or economic sanctions.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          3.3 You are responsible for keeping your account and login details secure and you are
          responsible for all activities that are carried out under your account. You should choose
          a strong, unique password, which you will not disclose to anyone else, or use with any
          other service. Acast will not be responsible for any losses suffered by you in
          circumstances where your account is used by someone else, except where this is
          demonstrably caused by Acast's negligence. Please let us know immediately if you believe
          that your account is compromised.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'italic',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          For Supporters
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          3.4 As a Supporter you do not need to create an account with Acast to be able to support
          your podcaster. You may need to create an account with Stripe in accordance with Stripe’s
          terms and conditions applicable from time to time.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          4. ACCEPTABLE USE OF THE ACAST SUPPORER SERVICE
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          4.1 Regardless of whether you are a podcaster who wants to let your listeners support you
          by making payments to you or if you are a Supporter who wants to support a podcaster by
          making payments to such podcaster, you must be at least eighteen (18) years old and/or
          otherwise be authorized to enter into agreements and accept to be bound by the Terms in
          order to use the Acast Supporter Service.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          4.2 Subject to your compliance with the Terms, Acast grants you a limited, personal,
          non-exclusive, revocable and non-transferable right and license to use the features and
          functionality of the Acast Supporter Service during the term.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          4.3 You agree that when using the Acast Supporter Service, you will not:
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          • re-produce, modify, copy, distribute, prepare derivate works of, translate, resell,
          perform or display to the public, or permit access to the Acast Supporter Service by any
          third party;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          • take any actions to artificially alter the streaming and/or download numbers of any
          audio content as measured by the Platform;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          • interfere with the Acast Supporter Service or disrupt any other user's access to the
          Acast Supporter Service;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          • unless provided for under mandatory law, reverse engineer, disassemble, attempt to gain
          unauthorized access to the Acast Supporter Service, or attempt to discover the underlying
          source code or structure of the Acast Supporter Service;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          • submit any content or data that contains mass mailings or any form of spam;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          • distribute viruses or any other technologies that may harm Acast or our affiliates or
          the interests of any other user of the Acast Supporter Service, or otherwise interfere
          with or disrupt our systems;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          use any robot, spider, data scraping or extraction tool or similar mechanism with respect
          to the Acast Supporter Service;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          • share account credentials or give others access to your account. In addition to, and
          without limiting the generality of the foregoing, you may not access the Acast Supporter
          Service for purposes of monitoring its performance, functionality or availability, or for
          any other competitive purposes; or
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          • use the account or the Acast Supporter Service in a way that breaches any applicable
          money laundering legislation, trade restrictions and/or economic sanctions; or
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          • use the account or the Acast Supporter Service other than in compliance with applicable
          laws and regulations.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          4.4 You acknowledge, regardless of whether you are a Supporters or a podcaster, that any
          bank and/or payment partner (such as Stripe, VISA, Mastercard etc.) used for transferring
          money for the purpose of supporting a podcaster or receiving such monetary support from a
          Supporter, may prohibit such transfer of money due to their internal policies, applicable
          laws , regulations and/or trade restrictions. Acast shall have no responsibility for any
          non-transferred amount due to such internal policies, applicable laws, regulations and/or
          trade restrictions.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          4.5 Podcaster remains solely and fully responsible towards supporter(s) for any and all
          amounts paid to podcaster, including clearly disclosing if any amounts paid will be used
          for a third party or third-party purpose or cause. Acast shall have no liability
          whatsoever for any such payments or causes.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          5. FOR PODCASTERS
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          5.1 Invite listeners
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          You can invite your listeners to support you through the Acast Support Service by
          including information and a link to the Acast Supporter Service in the show description.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          5.2 Payments
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          We aim to pay out your funds on the 7th working day each month following the month during
          which the payment was made, but delays may occur. We may block or hold funds for
          violations of the Terms or for compliance reasons, including collecting tax reporting
          information. When payments are delayed or blocked we aim to communicate the reason for
          such delay or block as soon as we possibly can. If you have questions about such a delay
          or block, please&nbsp;
        </span>
        <a href="mailto:support@acast.com" style={{ textDecoration: 'none' }}>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Arial',
              color: '#1155cc',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            contact us
          </span>
        </a>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          . We may also block payments from Supporters, if we deem them to be fraudulent. In the
          event that the balance on your account becomes negative (which may happen e.g. in the
          event of refunds), we have the right to recover such funds from future payments.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          5.3 Fees
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Acast will charge the podcaster a fee for provision of the Acast Supporter Service. The
          fee is 12% of each and any payment made by a Supporter. The 12% fee includes fees to a
          third party payment solution provider (currently Stripe) and VAT (where VAT is applicable)
          and the specific VAT amount will (where applicable) always be specified on each invoice.
          Minimum payment from a Supporter per transaction is 3 USD / 3 Euros / 2,5 GBP / 30 SEK and
          maximum payment from a Supporter per transaction is 50 USD / 50 Euros / 50 GBP / 500 SEK.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Additional fees may be charged by a podcaster’s and/or a Supporter’s bank and/or credit
          card provider. Additional fees for using the Acast Supporter Service may also be charged
          by your internet service provider/mobile phone operator. Acast does not control such
          fees/charges.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          5.4 Tax
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          The podcaster is responsible for reporting any and taxes including VAT.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          5.5 Personal data
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          In the event that Acast transfers any personal data of any Supporter to you via the Acast
          Supporter Service, you are responsible for keeping such data safe and in accordance with
          Acast instructions from time to time. You may not transfer such data to a third party
          without Acast’s written consent.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          6. FOR SUPPORTERS
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          6.1 Become a Supporter
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          If you want to support your podcaster you will need to add your card details or select any
          other available payment method as instructed and make the payment. By making the payment
          you will also accept the relevant Stripe terms and conditions as well as the relevant
          Stripe privacy policy applicable from time to time.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          6.2 Acast is not responsible for any podcaster’s offers, promises or content deliveries.
          Acast attempt to screen for fraudulent activities but cannot guarantee the identity of any
          podcaster or the validity of any offers, promises or content deliveries they make. We
          appreciate your help reporting any suspicious activities so we can keep Acast Supporter
          Service safe.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          6.3 Depending on your location, some banks and credit card providers may charge you a
          transaction fee when you submit a payment to support our podcaster. Acast does not control
          this charge. Please contact your bank for further information.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          7. OWNERSHIP, RIGHTS ETC.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          7.1 Acast is and remains the sole owner of the Platform and the Platform Services,
          including but not limited to all Intellectual Property Rights related to the Acast
          Supporter Service and all technology, software, design, images, information and other
          content and material on the Platform and in the Platform Services including Acast
          Supporter Service &nbsp;(collectively, the "Acast IP"), including future developments and
          enhancements. Furthermore, Acast is and remains the sole owner of Acast's trade names,
          trademarks, logos, copyrights, branding and all components thereof. Acast reserves any and
          all rights not expressly granted to you. These Terms do not confer upon you any right to,
          or in respect of, the Platform, the Platform Services including Acast Supporter Service or
          to any technology, software, information or material being part of such services. Acast
          expressly reserves all Intellectual Property Rights not expressly granted to you
          hereunder.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          7.3 As a podcaster you acknowledge and agree that you remain solely responsible for any
          content uploaded and/or made available to Supporters through the Platform. You may not
          upload, store, distribute, send, transmit, display, perform, make available or otherwise
          communicate to the public any content to which you do not hold the necessary rights
          (including rights to any sound recordings and/or musical works, lyrics, compositions etc.
          included in the content). Any unauthorized use of copyright protected material within your
          content (including by way of reproduction, distribution, modification, adaptation, public
          display, public performance, making available or otherwise communicating to the public via
          the Platform), independent of whether it is or becomes unauthorized at a later point, may
          constitute an infringement of third party rights and is strictly prohibited.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          7.4 As a podcaster you may not make available any content via the Platform that is
          defamatory, offensive or illegal. Acast reserves the right to remove any content that
          violates these Terms or any applicable law or which Acast for any other reason deems may
          harm Acast’s business.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          8. PERSONAL DATA
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Acast will collect and process certain personal data pertaining to you (as a podcaster or
          a Supporte) when you create an account, use the Platform and/or the Platform Services
          including Acast Supporter Service as well as when you as a Supporter gives the consent to
          store your personal data for the purpose of transferring such personal data to the
          podcaster you wish to Support. In the Acast Privacy Policies we inform you of the
          categories of data we collect, for what purposes we collect the data and how long we store
          data collected in connection with the Platform and the Platform Services including Acast
          Supporter Service. The Privacy Policies are available when you create your account (if you
          are a podcaster) or when you make a payment (if you are a Supporter) and on the Platform
          at all times.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          9. TERMINATION, DELETION OF ACCOUNT ETC.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          9.1 We can terminate or suspend your account at any time at our discretion. Your
          non-compliance with the Terms may result in the suspension of your access to the Platform
          and/or the Platform Services including the Acast Supporter Service or termination of your
          right to use the Platform and/or the Platform Services including the Acast Supporter
          Service. By using the Acast Supporter Service, you accept that Acast may, at any time and
          at its own discretion, close the Platform or parts of the Platform and discontinue the
          Platform Services including the Acast Supporter Service or parts thereof.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          9.2 In the event of termination, Acast agrees to remove your personal data in accordance
          with our Privacy Policies (as applicable). &nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          9.3 If you wish to delete your Stripe account, please contact Stripe.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          10. CHANGES TO THE SERVICES AND/OR THE TERMS
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          10.1 We reserve the right to modify, correct, develop, improve, or make any other change
          to, or discontinue (temporarily or permanently), the Platform and/or Platform Services
          including the Acast Supporter Service (or any part thereof) without notice, at any time.
          In the event of a permanent discontinuation of the Platform, we will, unless prohibited by
          applicable law or stock-exchange rules, inform you hereof no later than thirty (30) days
          prior to such discontinuation.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          10.2 Acast may revise these Terms from time to time at our sole discretion. When there is
          a new version of the Terms, you will receive notice hereof in the Platform and be prompted
          to approve the new version of the Terms. If you do not approve the new version of the
          Terms, you may not continue to use the Acast Supporter Service.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          11. DISCLAIMER
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          11.1 TO THE FULLEST EXTENT LEGALLY PERMISSIBLE, THE PLATFORM, THE PLATFORM SERVICES
          INCLUDING THE ACAST SUPPORTER SERVICE AS WELL AS ANY MATERIAL, INFORMATION AND ANY THIRD
          PARTY COMPONENTS PROVIDED THEREIN ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, AND
          ACAST DISCLAIM ALL WARRANTIES OF ANY KIND, EXPRESS, IMPLIED OR STATUTORY, INCLUDING BUT
          NOT LIMITED TO WARRANTIES OF TITLE OR NON-INFRINGEMENT OR IMPLIED WARRANTIES OF USE,
          MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. THE DISCLAIMER UNDER THIS CLAUSE ALSO
          APPLY TO OUR SUBSIDIARIES, AFFILIATES AND THIRD-PARTY SERVICE PROVIDERS.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          11.2 ACAST USES COMMERCIALLY REASONABLE ENDEAVORS TO ENSURE THAT ANY PAYMENT DATA, WHICH
          IS PROVIDED TO YOU VIA THE PLATFORM, IS CORRECT. HOWEVER, ACAST MAKES NO GUARANTEE THAT
          SUCH DATA IS ERROR-FREE.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          11.3 TO THE MAXIMUM EXTENT LEGALLY PERMISSIBLE, IN NO EVENT SHALL ACAST BE LIABLE FOR ANY
          DAMAGES WHATSOEVER INCLUDING, BUT NOT LIMITED TO, DIRECT OR INDIRECT DAMAGES OF ANY KIND
          (INCLUDING WITHOUT LIMITATION, LOSS OF GOODWILL, PROFITS OR DATA AND BUSINESS
          INTERRUPTION) ARISING HEREUNDER, RESULTING FROM OR ARISING OUT OF THE SERVICES, YOUR USE
          OR INABILITY TO USE THE SERVICES OR FAILURE OF THE PLATFORM AND/OR THE PLATFORM SERVICES
          TO PERFORM AS REPRESENTED OR EXPECTED. IN NO EVENT SHALL ACAST TOTAL LIABILITY HEREUNDER
          EXCEED AN AMOUNT CORRESPONDING TO FEES PAID BY YOU DURING THE THREE (3) MONTHS PRECEDING
          THE EVENT GIVING RISE TO YOUR CLAIM FOR COMPENSATION HEREUNDER.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          12. INDEMNIFICATION
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          YOU WILL INDEMNIFY, DEFEND, AND HOLD HARMLESS ACAST AND OUR AFFILIATES, AND EACH OF OUR
          RESPECTIVE DIRECTORS, OFFICERS, SHAREHOLDERS, EMPLOYEES, AND AGENTS, FROM AND AGAINST ALL
          THIRD-PARTY ACTIONS, DAMAGES, CLAIMS, LIABILITIES, DAMAGES, LOSSES, AND EXPENSES THAT
          ARISE FROM YOUR USE OF THE ACAST SUPPORTER SERVICE OR OTHERWISE ARISING OUT OF ANY BREACH
          OR ALLEGED BREACH OF ANY OF YOUR OBLIGATIONS SET FORTH IN THESE TERMS. THE INDEMNIFICATION
          UNDER THIS CLAUSE ALSO APPLY TO OUR THIRD-PARTY SERVICE PROVIDERS.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          13. MISCELLANEOUS
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          13.1 Acast has the right, fully or partly, to transfer our rights and obligations to third
          parties under these Terms. You have no right to transfer your rights and obligations under
          these Terms, unless you have Acast's written consent to do so.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          13.2 If any part, term or provision of the Terms is held to be illegal or unenforceable,
          the validity of the remainder of the Terms will not be affected. Any omission of Acast to
          enforce its rights under the Terms shall not be regarded as a waiver of such rights. It is
          expressly understood that all provisions regarding limitations of liability and
          indemnities will remain in full force and effect and shall survive the deletion of your
          account on the Platform.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          14. GOVERNING LAW AND DISPUTES
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          14.1 Any claim relating to the Platform or the use thereof will be governed by and
          interpreted in accordance with the laws of Sweden, without reference to its conflict of
          law principles.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          14.2 Any dispute, controversy or claim arising out of or in connection with this
          Agreement, or the breach, termination or invalidity thereof, shall be finally settled by
          the court of Stockholm (or in accordance with applicable consumer legislation if you are a
          consumer).&nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          15. CONTACT INFORMATION
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          If you have any questions, complaints or comments about Acast or our services then please
          contact us by email using support@acast.com, or in writing, by post, to Acast AB,
          Kungsgatan 28, 111 35 Stockholm, Sweden.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p>
        <br />
      </p>
    </Wrapper>
  );
};

export default TermsAndConditions;

import React, { useCallback, useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import config from 'shared/helpers/config';
import { matchType } from 'shared/customPropTypes/reactRouterType';
import { Form as FinalForm } from 'react-final-form';
import api from 'shared/api';
import { Text, unit, toast, Link } from '@acast-tech/decibel';
import LoaderWrapper from 'shared/components/LoaderWrapper';
import Form from './components/Form';

const Container = styled.div`
  display: grid;
  grid-template-columns: 50%;
  grid-row-gap: ${unit(10)};
  margin: ${unit(16)} ${unit(32)};
  min-height: 500px;
`;

const Finance = ({
  match: {
    params: { id },
  },
}) => {
  const [countries, setCountries] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  useEffect(() => {
    const fetchCountries = async () => {
      setIsProcessing(true);
      try {
        const res = await api.access.get('api/v1/countries');
        setCountries(res);
      } catch (e) {
        toast.danger(`There was a problem fetching a list of countries ${e.message}`, {
          closable: true,
          duration: 10000,
        });
      } finally {
        setIsProcessing(false);
      }
    };
    fetchCountries();
  }, []);

  const checkIfEu = useCallback(
    (selectedCountry) =>
      Boolean(
        countries.find((val) => {
          return val.code === selectedCountry && val.isEU;
        })
      ),
    [countries]
  );

  const saveVatData = React.useCallback(
    async (data) => {
      const res = await api.access.post(`api/v1/shows/${id}/vatinfo`, { body: data, auth: true });
      return res;
    },
    [id]
  );

  const onStripeClick = useCallback(
    async ({ country, type, vatNumber }) => {
      const isEu = checkIfEu(country);
      const isCompany = type === 'company';
      const data = {
        showid: id,
        country: country,
        isBusiness: isCompany,
        isEu: isEu,
        vatNumber: isCompany && isEu ? `${country}${vatNumber}` : undefined,
      };
      try {
        setIsProcessing(true);
        await saveVatData(data);

        const STRIPE_REDIRECT_URL = config.get('STRIPE_REDIRECT_URL');
        const STRIPE_CLIENT_ID = config.get('STRIPE_CLIENT_ID');

        window.location = `https://connect.stripe.com/express/oauth/authorize?redirect_uri=${STRIPE_REDIRECT_URL}&client_id=${STRIPE_CLIENT_ID}&state=${id}&suggested_capabilities[]=transfers&suggested_capabilities[]=card_payments&stripe_user[business_type]=${type}&stripe_user[country]=${country}`;
      } catch (e) {
        toast.danger(`There was a problem saving your financial data. ${e.message}`, {
          closable: true,
          duration: 10000,
        });
      } finally {
        setIsProcessing(false);
      }
    },
    [id, checkIfEu, saveVatData]
  );

  const initialValues = useMemo(() => ({ country: 'none', type: 'individual' }), []);
  return (
    <LoaderWrapper loading={isProcessing}>
      <Container>
        <div>
          <Text variant="h1">Financial information</Text>
          <Text variant="subtitle1">
            We need to know a little more about you in order to establish the Supporter feature and
            to connect you to Stripe - a global payment platform we use to process payments. Learn
            more about Stripe{' '}
            <Link target="_blank" rel="noopener noreferrer" href="https://stripe.com/connect">
              here
            </Link>
            .
          </Text>
        </div>
        {countries && (
          <FinalForm initialValues={initialValues} onSubmit={onStripeClick}>
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit} checkIfEu={checkIfEu} countries={countries} />
            )}
          </FinalForm>
        )}
      </Container>
    </LoaderWrapper>
  );
};

Finance.propTypes = {
  match: matchType.isRequired,
};

export default Finance;

export const camelCaseToWords = (string) =>
  string
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (str) => str.toUpperCase())
    .replace(/(\s)([a-z])/, (str) => str.toUpperCase());

export const isUrl = (value) => typeof value === 'string' && value.match(/^http(s)?:\/\//gm);

/**
 *
 * @param {string} src url we want to get filename from
 *
 * @returns {string} with filename
 */
export const createLabelFromSrc = (src) => {
  if (!src) return null;

  let fileName = src.substring(src.lastIndexOf('/') + 1);
  fileName = fileName.substring(fileName.indexOf('-') + 1);
  fileName = fileName.substring(fileName.indexOf('-') + 1);

  return fileName;
};

/**
 *
 * @param {string} url url to retreive query params from
 *
 * @returns {array} an array of arrays, e.g. [['key', 'value']]
 */
export const queryParamsFromUrl = (url) => {
  if (!url || typeof url !== 'string') return [];

  const regex = /^.*\?/gm;
  const paramsString = url.replace(regex, '');
  const params = new URLSearchParams(paramsString).entries();

  return [...params];
};

/**
 *
 * @param {string} number - A string to check if it contains only numbers.
 *
 * @returns {boolean} true or false;
 */
export const isNumbersOnly = (number) => {
  const exp = /^\d*$/gi;
  return exp.test(`${number}`);
};

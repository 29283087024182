import im from 'immutable';

export default im.Record({
  entity: '',
  name: '',
  path: '',
  fileName: '',
  progress: 0,
  error: null,
});

import config from 'shared/helpers/config';

config.set(window['acast/config']);

// TODO: implement relog method
const relogin = () => {};

export const APIS = {
  access: {
    url: config.get('ACCESS_URL'),
  },
  feeder: { url: config.get('FEEDER_URL') },
};

export const chainedCalls = {
  accessAndFeeder: async (id) =>
    Promise.all([
      calls.access.get(`api/v1/shows/${id}`).then((accessShow) => ({ accessShow })),
      calls.feeder.get(`api/v1/shows?url=${id}`).then((feederShow) => ({ feederShow })),
    ]),
};

const api = async (apiType, method, path = '', options) => {
  const { paramHeaders = {}, body, auth = false, ...rest } = options || {};
  const API_URL = APIS[apiType].url;
  let headers = {
    'Content-Type': 'application/json',
    ...paramHeaders,
  };
  if (auth) {
    const token = localStorage.getItem('token');

    headers = {
      ...headers,
      Authorization: `Bearer ${token}`,
    };
  }

  const res = await fetch(`${API_URL}/${path}`, {
    method,
    body: JSON.stringify(body),
    headers,
    ...rest,
  });

  // Unauthorized - Redirect to login screen
  if (res.status === 401) {
    relogin();
    return null;
  }

  let data = null;
  const text = await res.text();
  try {
    data = JSON.parse(text);
  } catch {
    data = { status: res.status, message: `${res.statusText || text} (${res.status})` };
  }

  // Throw a reject
  if (res.status >= 400) return Promise.reject(data);
  // All is good
  return data;
};

const calls = {
  access: {
    get: (path, options) => api('access', 'GET', path, options),
    put: (path, options) => api('access', 'PUT', path, options),
    post: (path, options) => api('access', 'POST', path, options),
  },
  feeder: {
    get: (path, options) => api('feeder', 'GET', path, options),
  },
};

export default calls;

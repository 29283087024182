import jwtDecode from 'jsonwebtoken/decode';
/**
 * Checks if we have a token and if that token is still valid.
 *
 * @returns `false` if token has expired or if none exists, or `true` if token exists and is valid
 */

export const tokenIsValid = () => {
  const token = localStorage.getItem('token');
  if (!token) return false;
  const decoded = jwtDecode(token);
  const now = Date.now();
  const expires = decoded.ttl * 1000;
  return expires > now;
};

export const logout = () => {
  // TODO: DECIDE HOW TO LOG OUT
  localStorage.removeItem('token');
  window.location = 'https://acast.com';
};
